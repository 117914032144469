import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  CircularProgress,
  styled,
  IconButton,
  Snackbar,
  Alert,
  AlertColor,
  Tooltip
} from '@mui/material';
import axios from 'axios';
import VehicleService from '../../Services/VehicleService';
import Vehicle from '../../Models/Vehicle';
import InspectionForm from '../Contractor/InspectionForm';
import InspectionFormsService from '../../Services/InspectionFormsService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContractorService from '../../Services/ContractorService';
import InspectionFormObject from '../../Models/InspectionForm';
import Contractor from '../../Models/Contractor';

const AdminApprovalDashboard = () => {
  const [inspectionVehicles, setInspectionVehicles] = useState<Vehicle[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [contractorDetails, setContractorDetails] = useState<Contractor | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [openInspectionForm, setOpenInspectionForm] = useState(false);

  const httpClient = axios.create();
  const vehicleService = new VehicleService(httpClient);
  const inspectionFormsService = new InspectionFormsService(httpClient);
  const contractorService = new ContractorService(httpClient);

  const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.action.selected : "none",
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    '& .MuiTableCell-root': {
      color: theme.palette.common.white,
      textTransform: 'uppercase',
    },
  }));

  const fetchInspectionForms = async () => {
    setLoading(true);
    try {
      const vehicles = await vehicleService.getVehiclesByStatus("Project Manager Approved");
      setInspectionVehicles(Array.isArray(vehicles) ? vehicles : []);
    } catch (error) {
      console.error('Failed to fetch inspection forms:', error);
      setInspectionVehicles([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInspectionForms();
  }, [triggerRefresh]);

  const handleSelectVehicle = async (vehicle: Vehicle) => {
    setFormLoading(true); // Start loading the form data
    try {
      const contractorDetails = await contractorService.getContractorById(vehicle.contractorId);
      setContractorDetails(contractorDetails);
      setSelectedVehicle(vehicle);
      setOpenInspectionForm(true); // Open the form after data is fetched
    } catch (error) {
      console.error('Error fetching contractor details:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to load contractor details');
      setSnackbarOpen(true);
    } finally {
      setFormLoading(false); // Stop loading once data is fetched
    }
  };

  const updateVehicleStatus = async (vehicleId: number, newStatus: string) => {
    try {
      const updatedVehicle = await vehicleService.updateVehicleStatus(selectedVehicle?.vehicleId ?? 0, selectedVehicle?.contractorId as number, newStatus);
      console.log(`Vehicle ${vehicleId} updated to ${newStatus}`);
      return updatedVehicle;
    } catch (error) {
      console.error("Error updating vehicle status:", error);
    }
  };

  const handleReviewInspectionForm = async (formData: InspectionFormObject) => {
    console.log("Reviewing inspection form:", formData);

    if (formData.formId === null) {
      console.error("Form ID is null, cannot proceed with review.");
      setSnackbarSeverity('error');
      setSnackbarMessage('Form ID is null, cannot proceed with review.');
      setSnackbarOpen(true);
      return;
    }

    try {
      await updateVehicleStatus(selectedVehicle?.vehicleId as number, formData.adminApprovalStatus);
      await inspectionFormsService.updateInspectionFormStatus(formData.formId, formData.adminApprovalComments, formData.adminApprovalStatus, formData.adminSignature);

      setSnackbarSeverity('success');
      setSnackbarMessage('Inspection form reviewed successfully');
      setSnackbarOpen(true);
      setOpenInspectionForm(false); // Close the form
      setTriggerRefresh(!triggerRefresh);
    } catch (error) {
      console.error("Error saving form data:", error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to review inspection form');
      setSnackbarOpen(true);
    }
  };

  const handleSaveFormData = async (formData: InspectionFormObject) => {
    console.log("Saving form data:", formData);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Vehicles Ready For Final Approval
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table aria-label="inspection forms table">
            <StyledTableHead>
              <TableRow>
                <TableCell>Vehicle Number</TableCell>
                <TableCell>Make</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Date Approved</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>View Inspection Form</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {inspectionVehicles.map(vehicle => (
                <StyledTableRow
                  key={vehicle.vehicleId}
                  selected={!!(selectedVehicle && vehicle.vehicleId === selectedVehicle.vehicleId)}
                  onClick={() => handleSelectVehicle(vehicle)}
                >
                  <TableCell>{vehicle.vehicleNumber}</TableCell>
                  <TableCell>{vehicle.make}</TableCell>
                  <TableCell>{vehicle.model}</TableCell>
                  <TableCell>{vehicle.year}</TableCell>
                  <TableCell>{vehicle.updatedAt ? new Date(vehicle.updatedAt).toLocaleDateString() : 'N/A'}</TableCell>
                  <TableCell>{vehicle.status}</TableCell>
                  <TableCell>
                    <Tooltip title="View Inspection Form">
                      <IconButton color="primary" onClick={(event) => {
                        event.stopPropagation(); // Prevent event bubbling
                        handleSelectVehicle(vehicle);
                      }}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedVehicle && contractorDetails && openInspectionForm && (
        <InspectionForm
          mode="adminApproval"
          open={openInspectionForm}
          onClose={() => {
            setSelectedVehicle(null);
            setContractorDetails(null);
            setOpenInspectionForm(false);
          }}
          initialVehicle={selectedVehicle}
          onSave={handleSaveFormData}
          contractorDetails={contractorDetails}
          onReview={handleReviewInspectionForm}
        />
      )}

      {formLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <CircularProgress />
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AdminApprovalDashboard;
