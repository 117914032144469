import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, TableSortLabel, IconButton, Box, Modal } from '@mui/material';
import Project from '../../Models/Project';
import EditIcon from '@mui/icons-material/Edit';
import ProjectManagerApprovalTable from './ProjectManagerApprovalTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import VehicleService from '../../Services/VehicleService';
import Vehicle from '../../Models/Vehicle';

type ProjectTableProps = {
  projects: Project[];
  onEditClick: (project: Project) => void;
};

const ProjectTable: React.FC<ProjectTableProps> = ({ projects, onEditClick }) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [showApprovalView, setShowApprovalView] = useState(false);
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);

  const httpClient = axios.create();
  const vehicleService = new VehicleService(httpClient);


  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedProjects = [...projects].sort((a, b) => {
    if (['attachedVehicleCount', 'projectNumber'].includes(orderBy)) return 0;
    if (orderBy === '') return 0;

    if (orderBy === 'startDate') {
      const dateA = new Date(a.startDate).getTime();
      const dateB = new Date(b.startDate).getTime();
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    }

    if (orderBy === 'lastUpdated') {
      const dateA = new Date(a.lastUpdated).getTime();
      const dateB = new Date(b.lastUpdated).getTime();
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    }

    // Check if the property exists and is a string before calling toLowerCase
    const valA = (a as any)[orderBy];
    const valB = (b as any)[orderBy];
    if (typeof valA === 'string' && typeof valB === 'string') {
      return order === 'asc' ? valA.toLowerCase().localeCompare(valB.toLowerCase()) : valB.toLowerCase().localeCompare(valA.toLowerCase());
    }

    // If not a string or the property does not exist, do not sort
    return 0;
  });

  const handleApprovalClick = (project: Project) => {
    setCurrentProject(project);
    setShowApprovalView(true); // Show the approval view
  };

  const handleBackToProjectList = () => {
    setShowApprovalView(false); // Hide the approval view
    setCurrentProject(null); // Clear the current project selection
  };

  const RefreshVehicleList = async () => {
    // Refresh the vehicle list here
    console.log('Refreshing vehicles');
    const updatedVehicles = await vehicleService.getVehiclesByProjectId(currentProject?.projectId as number);
    setVehicleList(updatedVehicles);
    if (currentProject) {
      currentProject.vehicles = updatedVehicles;
    }
  };

  // If the approval view is active, render the approval table instead of the project list
  if (showApprovalView && currentProject) {
    return (
      <div style={{ position: 'relative', zIndex: 2 }}>
        <ProjectManagerApprovalTable
          project={currentProject}
          onBack={handleBackToProjectList}
          vehicles={currentProject.vehicles || []}
          onRefresh={() => RefreshVehicleList()} // Wrap the function call inside a JSX element
        />
      </div>
    );
  }


  return (
    <TableContainer component={Paper} elevation={4} sx={{ borderRadius: 2, }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead sx={{ bgcolor: 'primary.main' }}>
          <TableRow>
            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>Project Number</TableCell>
            {['Location', 'Status'].map((col) => (
              <TableCell key={col} sx={{ color: 'common.white', textTransform: 'uppercase' }}>
                <TableSortLabel
                  active={orderBy === col.toLowerCase()}
                  direction={orderBy === col.toLowerCase() ? order : 'asc'}
                  onClick={() => handleSort(col.toLowerCase())}
                >
                  {col}
                </TableSortLabel>
              </TableCell>
            ))}

            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>Vehicle Count</TableCell>
            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>
              <TableSortLabel
                active={orderBy === 'startDate'}
                direction={orderBy === 'startDate' ? order : 'asc'}
                onClick={() => handleSort('startDate')}
              >
                Start Date
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>
              <TableSortLabel
                active={orderBy === 'lastUpdated'}
                direction={orderBy === 'lastUpdated' ? order : 'asc'}
                onClick={() => handleSort('lastUpdated')}
              >
                Last Modified
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: 'common.white' }}>View/Edit</TableCell>
            <TableCell sx={{ color: 'common.white' }}>Vehicles to Approve</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedProjects.map((project) => {
                      const vehiclesToApprove = project.vehicles?.filter(vehicle =>
                        vehicle.status === 'Site Lead Approved' || vehicle.status === 'Admin Declined'
                      ) || [];
            return (

              <TableRow key={project.projectId} hover>
                <TableCell>{project.projectNumber}</TableCell>
                <TableCell>{project.location}</TableCell>
                <TableCell>{project.status}</TableCell>
                <TableCell>{project.vehicles?.length || 0}</TableCell>
                <TableCell>{new Date(project.startDate).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(project.lastUpdated).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => onEditClick(project)}>
                    <EditIcon />
                  </IconButton>

                </TableCell>
                <TableCell>
                  {vehiclesToApprove.length > 0 && (
                    <Button
                      variant="contained"
                      startIcon={<CheckCircleIcon />}
                      sx={{
                        bgcolor: '#4caf50',
                        color: 'white',
                        '&:hover': {
                          bgcolor: '#388e3c',
                        },
                      }}
                      onClick={() => handleApprovalClick(project)}
                    >
                      {`Approve Vehicles (${vehiclesToApprove.length})`}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

    </TableContainer>
  );
};


export default ProjectTable;
