import React, { useState, useEffect, useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Paper,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Dialog,
  Box,
  Avatar,
  CircularProgress,
  DialogTitle,
  IconButton,
  TablePagination,
  Snackbar,
  Alert,
  styled,
  TableSortLabel,
  AlertColor,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  Close as CloseIcon,
  Warning as WarningIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  RateReview as ReviewIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import VehicleService from '../../Services/VehicleService';
import ProjectService from '../../Services/ProjectService';
import ContractorService from '../../Services/ContractorService';
import InspectionFormsService from '../../Services/InspectionFormsService';
import ExhibitAComponent from './ExhibitAComponent';
import InspectionForm from './InspectionForm';
import Project from '../../Models/Project';
import Vehicle from '../../Models/Vehicle';
import ExhibitA from '../../Models/ExhibitA';
import InspectionFormObject from '../../Models/InspectionForm';
import { logError, logInfo, initLogGroupAndStream } from '../../Utilities/AWSConfig';
import Contractor from '../../Models/Contractor';

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CustomAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.error.main,
  '& .MuiAlert-icon': {
    color: theme.palette.error.main,
  },
  maxWidth: '80%',
  margin: '20px 0px',
}));

const ContractorDashboard: React.FC = () => {
  const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [inProgressVehicles, setInProgressVehicles] = useState<Vehicle[]>([]);
  const [exhibitAForms, setExhibitAForms] = useState<ExhibitA[]>([]);
  const [openExhibitA, setOpenExhibitA] = useState(false);
  const [currentExhibitAData, setCurrentExhibitAData] = useState<ExhibitA | null>(null);
  const [isSiteLead, setIsSiteLead] = useState(false);
  const [siteLeadProjects, setSiteLeadProjects] = useState<Project[]>([]);
  const [vehiclesFromSignedExhibitAs, setVehiclesFromSignedExhibitAs] = useState<Vehicle[]>([]);
  const [openInspectionForm, setOpenInspectionForm] = useState(false);
  const [selectedVehicleForInspection, setSelectedVehicleForInspection] = useState<Vehicle>();
  const MAX_VEHICLES_PER_DAY = 2;
  const [showMaxVehiclesAlert, setShowMaxVehiclesAlert] = useState(false);
  const [exhibitALoaded, setExhibitALoaded] = useState(false);
  const [contractorDetails, setContractorDetails] = useState<Contractor | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefetchExhibitA, setShouldRefetchExhibitA] = useState(false);
  const httpClient = axios.create();
  const projectService = new ProjectService(httpClient);
  const vehicleService = new VehicleService(httpClient);
  const contractorService = new ContractorService(httpClient);
  const inspectionFormService = new InspectionFormsService(httpClient);
  const { user } = useAuth0();
  const [inspectionMode, setInspectionMode] = useState<'submit' | 'review'>('submit');
  const [reviewVehicles, setReviewVehicles] = useState<Vehicle[]>([]);
  const [reviewedContractorDetails, setReviewedContractorDetails] = useState<Contractor>();
  const [exhibitAPage, setExhibitAPage] = useState(0);
  const [exhibitaRowsPerPage, setExhibitARowsPerPage] = useState(5);
  const [reviewVehiclesPage, setReviewVehiclesPage] = useState(0);
  const [reviewVehiclesRowsPerPage, setReviewVehiclesRowsPerPage] = useState(5);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [searchQuery, setSearchQuery] = useState('');
  type SortableKeys = 'projectLocation' | 'status' | 'updatedAt';

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'asc' | 'desc' }>({ key: 'updatedAt', direction: 'desc' });

  const sortedExhibitAForms = useMemo(() => {
    let sortableForms = [...exhibitAForms];
    if (sortConfig !== null) {
      sortableForms.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof ExhibitA];
        const bValue = b[sortConfig.key as keyof ExhibitA];
  
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableForms;
  }, [exhibitAForms, sortConfig]);
  
  const filteredReviewVehicles = useMemo(() => {
    if (!searchQuery) return reviewVehicles;
  
    return reviewVehicles.filter(vehicle => {
      const project = projects.find(p => p.projectId === vehicle.projectId);
      return (
        vehicle?.vehicleNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        project?.location.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [reviewVehicles, projects, searchQuery]);  // Dependencies to trigger recalculation
  
  const sortedReviewVehicles = useMemo(() => {
    return filteredReviewVehicles.sort((a, b) => {
      const dateA = new Date(a.updatedAt ?? 0).getTime();
      const dateB = new Date(b.updatedAt ?? 0).getTime();
      return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
    });
  }, [filteredReviewVehicles, sortConfig]);
  
  // Memoize the vehicles to display in the current page
  const reviewVehiclesDisplayRows = useMemo(() => {
    return sortedReviewVehicles.slice(
      reviewVehiclesPage * reviewVehiclesRowsPerPage,
      reviewVehiclesPage * reviewVehiclesRowsPerPage + reviewVehiclesRowsPerPage
    );
  }, [sortedReviewVehicles, reviewVehiclesPage, reviewVehiclesRowsPerPage]);

  const requestSort = (key: SortableKeys) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const effectiveContractorDetails = inspectionMode === 'review' && reviewedContractorDetails ? reviewedContractorDetails : contractorDetails;

  useEffect(() => {
    const fetchContractorDetails = async () => {
      if (user?.email) {
        setIsLoading(true);
        try {
          const details = await contractorService.getContractorByEmail(user.email);
          setContractorDetails(details);
        } catch (error) {
          console.error("Error fetching contractor details:", error);
          await logError({ message: 'Error fetching contractor details', stack: (error as any).stack });
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchContractorDetails();
  }, [user?.email]);

  useEffect(() => {
    const fetchExhibitAsAndRelatedData = async () => {
      if (contractorDetails?.contractorId) {
        setIsLoading(true);
        try {
          const inProgressVehicles = await vehicleService.getVehiclesByContractorIdAndStatus(contractorDetails.contractorId, 'In Progress') || [];
          const declinedVehicles = await vehicleService.getVehiclesByContractorIdAndStatus(contractorDetails.contractorId, 'Site Lead Declined') || [];
          inProgressVehicles.push(...declinedVehicles);
          const exhibitAForms = await contractorService.getExhibitAsByContractor(contractorDetails.contractorId);
    
          // Batch the related state updates together
          setExhibitAForms(exhibitAForms);
          setInProgressVehicles(inProgressVehicles.length > 0 ? inProgressVehicles : []);
          setShouldRefetchExhibitA(false);
          setExhibitALoaded(true);
        } catch (error) {
          console.error("Error fetching Exhibit A forms:", error);
          await logError({ message: 'Error fetching Exhibit A forms', stack: (error as any).stack });
        } finally {
          setIsLoading(false);  // Final state update for loading flag
        }
      }
    };

    if (contractorDetails?.contractorId || shouldRefetchExhibitA) {
      fetchExhibitAsAndRelatedData();
    }
  }, [contractorDetails?.contractorId, shouldRefetchExhibitA]);

  useEffect(() => {
    if (exhibitALoaded) {
      fetchProjects(contractorDetails?.contractorId as number);
      fetchProjectsAndVehicles();
    }
  }, [exhibitALoaded]);

  useEffect(() => {
    const initializeLogs = async () => {
      await initLogGroupAndStream();
    };
    initializeLogs();
  }, []);

  const updateVehicleStatus = async (vehicleId: number, newStatus: string) => {
    try {
      const contractorId = inspectionMode === 'review' && reviewedContractorDetails ? reviewedContractorDetails.contractorId : contractorDetails?.contractorId;
      const updatedVehicle = await vehicleService.updateVehicleStatus(vehicleId, contractorId as number, newStatus);
      return updatedVehicle;
    } catch (error) {
      console.error("Error updating vehicle status:", error);
      await logError({ message: 'Error updating vehicle status', stack: (error as any).stack });
    }
  };

  const fetchProjects = async (contractorId: number) => {
    try {
      const projects: Project[] = await projectService.getProjectsByContractorId(contractorId);
      let siteLeadProjects: Project[] = [];
      let signedExhibitAProjects: Project[] = [];
      const reviewVehicles: Vehicle[] = [];
      if (projects.length > 0) {
        const updatedProjects = await Promise.all(projects.map(async project => {
          const vehicles: Vehicle[] = await vehicleService.getVehiclesByProjectId(project.projectId);
          const filteredVehicles = vehicles.filter(vehicle => vehicle.status === 'Review' || vehicle.status === 'Project Manager Declined');
          reviewVehicles.push(...filteredVehicles);
          if (project.siteLeadIds) {
            const siteLeadIdsArray = project.siteLeadIds.includes(',')
              ? project.siteLeadIds.split(',').map(Number)
              : [parseInt(project.siteLeadIds)];
            if (siteLeadIdsArray.includes(contractorDetails?.contractorId as number)) {
              siteLeadProjects.push({ ...project, vehicles: filteredVehicles });
            }
          }
          const isLinkedToSignedExhibitA = exhibitAForms.some(exhibitA =>
            exhibitA.projectId === project.projectId && exhibitA.status === 'Signed'
          );
          if (isLinkedToSignedExhibitA) {
            signedExhibitAProjects.push({ ...project, vehicles });
          }
          return { ...project, vehicles };
        }));
        setReviewVehicles(reviewVehicles);
        setProjects(updatedProjects);
        setSiteLeadProjects(siteLeadProjects);
        setIsSiteLead(siteLeadProjects.length > 0);
        setSelectedProject(signedExhibitAProjects.length > 0 ? signedExhibitAProjects[0] : null);
      } else {
        setProjects([]);
        setSiteLeadProjects([]);
        setSelectedProject(null);
      }
    } catch (error) {
      console.error("Error fetching projects and vehicles:", error);
      await logError({ message: 'Error fetching projects and vehicles', stack: (error as any).stack });
    }
  };

  const fetchProjectsAndVehicles = async () => {
    try {
      let allVehiclesFromSignedAndStartedProjects = [];
      const signedExhibitAProjectIds = exhibitAForms
        .filter(exhibitA => exhibitA.status === 'Signed')
        .map(exhibitA => exhibitA.projectId);
      const projects = await Promise.all(signedExhibitAProjectIds.map(projectId =>
        projectService.getProjectById(projectId)));
      const startedProjects = projects.filter(project => {
        const startDate = new Date(project.startDate);
        const now = new Date();
        return startDate < now;
      });
      for (const project of startedProjects) {
        const vehicles = await vehicleService.getVehiclesByProjectId(project.projectId);
        allVehiclesFromSignedAndStartedProjects.push(...vehicles);
      }
      setVehiclesFromSignedExhibitAs(allVehiclesFromSignedAndStartedProjects);
    } catch (error) {
      console.error("Error fetching vehicles from signed and started projects:", error);
      await logError({ message: 'Error fetching vehicles from signed and started projects', stack: (error as any).stack });
    }
  };

  const handleAddToQueue = async (vehicle: Vehicle) => {
    if (inProgressVehicles.length >= MAX_VEHICLES_PER_DAY) {
      setShowMaxVehiclesAlert(true);
      return;
    }
    
    const updatedVehicle = await updateVehicleStatus(vehicle.vehicleId, "In Progress");
  
    setInProgressVehicles(prev => [...prev, updatedVehicle].filter(Boolean) as Vehicle[]);
    setVehiclesFromSignedExhibitAs(prevVehicles => prevVehicles.filter(v => v.vehicleId !== vehicle.vehicleId));
    setShowMaxVehiclesAlert(false);  // Reset the alert
  };

  const handleRemoveFromQueue = async (vehicle: Vehicle) => {
    const updatedVehicle = await vehicleService.updateVehicleStatus(vehicle.vehicleId, contractorDetails?.contractorId as number, "New");
    setInProgressVehicles(prevVehicles => prevVehicles.filter(v => v.vehicleId !== vehicle.vehicleId));
    setVehiclesFromSignedExhibitAs(prevVehicles => [...prevVehicles, updatedVehicle] as Vehicle[]);
  };

  const handleVehicleSelect = (event: any) => {
    const vehicleId = Number(event.target.value);
    const selectedVehicle = vehiclesFromSignedExhibitAs.find(v => v.vehicleId === vehicleId);
    if (selectedVehicle) {
      handleAddToQueue(selectedVehicle);
      setSelectedVehicleId(vehicleId);
    }
  };

  const handleEditVehicle = (vehicleId: number) => {
    setInspectionMode('submit');
    const vehicleForInspection = inProgressVehicles.find(vehicle => vehicle.vehicleId === vehicleId);
    setSelectedVehicleForInspection(vehicleForInspection);
    setOpenInspectionForm(true);
  };

  const handleOpenExhibitA = (exhibitAData: ExhibitA) => {
    setCurrentExhibitAData(exhibitAData);
    setOpenExhibitA(true);
  };

  const handleCloseDialog = () => {
    setOpenExhibitA(false);
    setShouldRefetchExhibitA(true);
    setExhibitALoaded(false);
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleSaveFormData = async (formData: InspectionFormObject, vehicle: Vehicle): Promise<any> => {
    try {
      formData.vehicleId = vehicle.vehicleId.toString();
      const updatedFormData = { ...formData };
      if (formData.vehicleIdImage) {
        updatedFormData.vehicleIdImageBase64 = await readFileAsBase64(formData.vehicleIdImage);
      }
      if (formData.vinImage) {
        updatedFormData.vinImageBase64 = await readFileAsBase64(formData.vinImage);
      }
      updatedFormData.discrepancyImagesBase64 = await Promise.all(
        formData.discrepancyImages.map(file => readFileAsBase64(file))
      );

      updatedFormData.equipmentDetails = await Promise.all(
        formData.equipmentDetails.map(async detail => {
          const equipmentImageBase64 = detail.equipmentImage
            ? await readFileAsBase64(detail.equipmentImage)
            : detail.equipmentImageBase64;

          const serialNumberImageBase64 = detail.serialNumberImage
            ? await readFileAsBase64(detail.serialNumberImage)
            : detail.serialNumberImageBase64;

          const installationImageBase64 = detail.installationImage
            ? await readFileAsBase64(detail.installationImage)
            : detail.installationImageBase64;

          const calibrationImageBase64 = detail.calibrationImage
            ? await readFileAsBase64(detail.calibrationImage)
            : detail.calibrationImageBase64;

          return {
            ...detail,
            equipmentImageBase64,
            serialNumberImageBase64,
            installationImageBase64,
            calibrationImageBase64,
            // Ensure the paths are retained for existing images
            equipmentImagePath: detail.equipmentImagePath || detail.equipmentImageBase64,
            serialNumberImagePath: detail.serialNumberImagePath || detail.serialNumberImageBase64,
            installationImagePath: detail.installationImagePath || detail.installationImageBase64,
            calibrationImagePath: detail.calibrationImagePath || detail.calibrationImageBase64,
          };
        })
      );

      if (vehicle) {
        await vehicleService.updateVehicles([vehicle]);
      }

      let savedInspectionForm;
      if (!formData.formId) {
        setIsLoading(true);
        savedInspectionForm = await inspectionFormService.saveInspectionForm(updatedFormData);
        if (formData.signature && selectedVehicleForInspection) {
          await updateVehicleStatus(selectedVehicleForInspection.vehicleId, 'Review');
        }
      } else {
        setIsLoading(true);
        savedInspectionForm = await inspectionFormService.updateInspectionForm(updatedFormData);
        if (formData.signature && selectedVehicleForInspection) {
          await updateVehicleStatus(selectedVehicleForInspection.vehicleId, 'Review');
        }
      }

      setSnackbarMessage('Inspection form saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      await logInfo({ message: 'Inspection form saved successfully', formData: updatedFormData, vehicle });
      return savedInspectionForm;
    } catch (error) {
      const errorMessage = error instanceof Error ? `${error.message}\n${error.stack}` : 'Failed to save the form. Please try again.';
      setSnackbarMessage(`Failed to save the form: ${errorMessage}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error saving form data', stack: (error as any).stack });
    } finally {
      setIsLoading(false);
      setShouldRefetchExhibitA(true);
      setExhibitALoaded(false);
    }
  };

  const handleReviewInspectionForm = async (formData: InspectionFormObject) => {
    try {
      const updatedFormData = {
        formId: formData.formId,
        reviewerComments: formData.siteLeadComments,
        approvalStatus: formData.statusSiteLead,
        siteLeadSignature: formData.siteLeadSignature,
      };
      await inspectionFormService.updateInspectionFormStatus(updatedFormData.formId ?? 0, updatedFormData.reviewerComments, updatedFormData.approvalStatus, updatedFormData.siteLeadSignature);
      if (updatedFormData.approvalStatus === 'Approved') {
        await updateVehicleStatus(selectedVehicleForInspection?.vehicleId as number, 'Site Lead Approved');
      } else if (updatedFormData.approvalStatus === 'Declined') {
        await updateVehicleStatus(selectedVehicleForInspection?.vehicleId as number, 'Site Lead Declined');
      }
      setSnackbarMessage('Inspection form reviewed successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error saving form data:", error);
      setSnackbarMessage('Failed to review the form. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error reviewing inspection form', stack: (error as any).stack });
    } finally {
      setIsLoading(false);
      setShouldRefetchExhibitA(true);
      setExhibitALoaded(false);
    }
  };

  const getStatusStyle = (status: any) => {
    switch (status) {
      case 'In Progress':
        return { backgroundColor: '#E8F5E9' };
      case 'Site Lead Declined':
        return { backgroundColor: '#FFFDE7' };
      case 'Project Manager Declined':
        return { backgroundColor: '#F8D7DA' }; // Light red
      default:
        return {};
    }
  };

  const handleChangeExhibitAPage = (event: any, newPage: React.SetStateAction<number>) => {
    setExhibitAPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    setExhibitARowsPerPage(parseInt(event.target.value, 10));
    setExhibitAPage(0);
  };

  const exhibitADisplayRows = sortedExhibitAForms.slice(exhibitAPage * exhibitaRowsPerPage, exhibitAPage * exhibitaRowsPerPage + exhibitaRowsPerPage);

  const handleChangeReviewVehiclesPage = (event: any, newPage: React.SetStateAction<number>) => {
    setReviewVehiclesPage(newPage);
  };

  const handleChangeReviewVehiclesRowsPerPage = (event: { target: { value: string; }; }) => {
    setReviewVehiclesRowsPerPage(parseInt(event.target.value, 10));
    setReviewVehiclesPage(0);
  };

  // Utility function to format date
  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const renderVehicleDropdown = () => {
    const projectInfo = new Map();
    projects.forEach(project => {
      projectInfo.set(project.projectId, project.location);
    });
    const newVehicles = vehiclesFromSignedExhibitAs.filter(vehicle => vehicle.status === 'New');
    return (
      <FormControl fullWidth>
        <InputLabel>Vehicle</InputLabel>
        <Select
          value={selectedVehicleId || ''}
          label="Vehicle"
          onChange={handleVehicleSelect}
        >
          {newVehicles.map(vehicle => (
            <MenuItem key={vehicle.vehicleId} value={vehicle.vehicleId}>
              {`${projectInfo.get(vehicle.projectId)}: ${vehicle.vehicleNumber || 'No Vehicle Number Set'} - ${vehicle.vehicleType}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderVehicleApprovalTable = () => {
    const handleReviewInspectionForm = async (vehicleId: number) => {
      try {
        setInspectionMode('review');
        const vehicleForInspection = reviewVehicles.find(vehicle => vehicle.vehicleId === vehicleId);

        if (vehicleForInspection) {
          setSelectedVehicleForInspection(vehicleForInspection);

          const reviewedContractorDetails = await contractorService.getContractorById(vehicleForInspection.contractorId);
          setReviewedContractorDetails(reviewedContractorDetails);

          setOpenInspectionForm(true);
        }
      } catch (error) {
        console.error("Error during inspection form review:", error);
        await logError({ message: "Error during inspection form review", stack: (error as any).stack });
      }
    };

    // Check if contractorDetails and projects are loaded before rendering
    if (!contractorDetails || !projects.length) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography variant="h6">Loading vehicle data...</Typography>
        </Box>
      );
    }

    return (
      <Card style={{ margin: '20px 0', backgroundColor: '#f7f7f7' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Vehicles Ready To Review</Typography>
          <TextField
            placeholder="Search"
            fullWidth
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
            <Table sx={{ minWidth: 320 }}>
              <TableHead sx={{ bgcolor: '#1565C0' }}>
                <TableRow>
                  <TableCell sx={{ color: 'common.white', textTransform: 'uppercase', fontSize: { xs: '0.8rem', sm: '1rem' } }}>Vehicle</TableCell>
                  <TableCell sx={{ color: 'common.white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>PROJECT-LOCATION</TableCell>
                  <TableCell sx={{ color: 'common.white', textTransform: 'uppercase', fontSize: { xs: '0.8rem', sm: '1rem' }, display: { xs: 'none', sm: 'table-cell' } }}>
                    <TableSortLabel
                      active={sortConfig.key === 'updatedAt'}
                      direction={sortConfig.direction}
                      onClick={() => requestSort('updatedAt')}
                    >
                      Last Updated
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: 'common.white', textTransform: 'uppercase', fontSize: { xs: '0.8rem', sm: '1rem' }, display: { xs: 'none', sm: 'table-cell' } }}>Status</TableCell>
                  <TableCell sx={{ color: 'common.white', fontSize: { xs: '0.8rem', sm: '1rem' } }}>REVIEW</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewVehiclesDisplayRows.flatMap(vehicle => {
                  // Store project and contractor IDs to avoid redundant operations
                  const project = projects.find(p => p.projectId === vehicle.projectId);
                  const contractorId = contractorDetails?.contractorId;

                  // Only display if the contractor is a site lead for this project
                  if (!project || !project.siteLeadIds.split(',').map(Number).includes(contractorId)) return null;

                  // Avoid redundant checks and calls
                  const formattedUpdatedAt = vehicle.updatedAt ? formatDate(vehicle.updatedAt) : '-';

                  return (
                    <TableRow key={vehicle.vehicleId} style={getStatusStyle(vehicle.status)}>
                      <TableCell>{vehicle.vehicleNumber}</TableCell>
                      <TableCell>{`${project.projectNumber}-${project.location}`}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{formattedUpdatedAt}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{vehicle.status}</TableCell>
                      <TableCell>
                        <IconButton size="small" color="primary" onClick={() => handleReviewInspectionForm(vehicle.vehicleId)}>
                          <ReviewIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredReviewVehicles.length}
              rowsPerPage={reviewVehiclesRowsPerPage}
              page={reviewVehiclesPage}
              onPageChange={handleChangeReviewVehiclesPage}
              onRowsPerPageChange={handleChangeReviewVehiclesRowsPerPage}
              sx={{
                '.MuiTablePagination-toolbar': {
                  flexWrap: 'wrap',
                },
                '.MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon': {
                  display: { xs: 'none', sm: 'block' },
                },
                '.MuiTablePagination-displayedRows': {
                  margin: { xs: 'auto', sm: '0' },
                },
                '.MuiTablePagination-actions': {
                  flexShrink: 0,
                  marginLeft: { xs: 0, sm: '20px' },
                },
              }}
            />
          </TableContainer>
        </CardContent>
      </Card>
    );
  };

  return (
    <Paper style={{ padding: '20px', margin: '20px', overflow: 'hidden' }}>
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
            <Typography variant="h6" component="div" color='white' sx={{ marginTop: 2 }}>
              Loading...
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ margin: '20px', overflow: 'hidden' }}>
        {contractorDetails && (
          <Card elevation={3} sx={{ marginBottom: '20px', overflow: 'hidden', borderRadius: '12px', background: 'linear-gradient(to right, #6a11cb 0%, #2575fc 100%)' }}>
            <CardContent sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
              color: 'common.white',
            }}>
              <Avatar alt="Profile Picture" src={user?.picture} />
              <Typography variant="h6" component="div" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' }, fontWeight: 'medium' }}>
                Welcome back, {contractorDetails.firstName}!
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
      {renderVehicleDropdown()}
      <Card style={{ margin: '20px 0', backgroundColor: '#f7f7f7' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Exhibit A Forms</Typography>
          <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
            <Table sx={{ minWidth: 320, minHeight: 250 }}>
              <TableHead sx={{ bgcolor: '#1565C0' }}>
                <TableRow>
                  <TableCell
                    sx={{ color: 'common.white', textTransform: 'uppercase', fontSize: { xs: '0.8rem', sm: '1rem' } }}
                  >
                    PROJECT-LOCATION
                  </TableCell>
                  <TableCell
                    sx={{ color: 'common.white', textTransform: 'uppercase', fontSize: { xs: '0.8rem', sm: '1rem' } }}
                    sortDirection={sortConfig?.key === 'status' ? sortConfig.direction : false}
                  >
                    <TableSortLabel
                      active={sortConfig?.key === 'status'}
                      direction={sortConfig?.direction}
                      onClick={() => requestSort('status')}
                    >
                      STATUS
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ color: 'common.white', textTransform: 'uppercase', fontSize: { xs: '0.8rem', sm: '1rem' } }}
                  >
                    EDIT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exhibitADisplayRows.map((form, index) => (
                  <CustomTableRow key={index}>
                    <TableCell>{form.name}</TableCell>
                    <TableCell>{form.status}</TableCell>
                    <TableCell>
                      <IconButton size="small" color="primary" onClick={() => handleOpenExhibitA(form)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={exhibitAForms.length}
              rowsPerPage={exhibitaRowsPerPage}
              page={exhibitAPage}
              onPageChange={handleChangeExhibitAPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                '.MuiTablePagination-toolbar': {
                  flexWrap: 'wrap',
                },
                '.MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon': {
                  display: { xs: 'none', sm: 'block' },
                },
                '.MuiTablePagination-displayedRows': {
                  margin: { xs: 'auto', sm: '0' },
                },
                '.MuiTablePagination-actions': {
                  flexShrink: 0,
                  marginLeft: { xs: 0, sm: '20px' },
                },
              }}
            />
          </TableContainer>
          <Dialog open={openExhibitA} onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              handleCloseDialog();
            }
          }}>
            <DialogTitle sx={{ marginBottom: 2, p: 2 }}>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {openExhibitA && <ExhibitAComponent
              exhibitAId={currentExhibitAData?.exhibitAId as number}
              passedSignature={currentExhibitAData?.signature as string}
              contractorId={currentExhibitAData?.contractorId as number}
              projectId={currentExhibitAData?.projectId as number}
              onSubmitAndClose={handleCloseDialog}
            />}
          </Dialog>
        </CardContent>
      </Card>
      {showMaxVehiclesAlert && (
        <CustomAlert
          icon={<WarningIcon fontSize="inherit" />}
          severity="warning"
        >
          You have reached your limit of {MAX_VEHICLES_PER_DAY} vehicles for the day. Please complete a vehicle in progress before adding more.
        </CustomAlert>
      )}
      <Card style={{ margin: '20px 0', backgroundColor: '#f7f7f7' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            In Progress Vehicles
          </Typography>
          <TableContainer sx={{ overflowX: 'auto' }} component={Paper}>
            <Table sx={{ minWidth: 320 }}>
              <TableHead sx={{ bgcolor: '#1565C0' }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: 'common.white',
                      textTransform: 'uppercase',
                      fontSize: { xs: '0.8rem', sm: '1rem' },
                    }}
                  >
                    Vehicle Number
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'common.white',
                      textTransform: 'uppercase',
                      fontSize: { xs: '0.8rem', sm: '1rem' },
                    }}
                  >
                    Location
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'common.white',
                      textTransform: 'uppercase',
                      fontSize: { xs: '0.8rem', sm: '1rem' },
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'common.white',
                      textTransform: 'uppercase',
                      fontSize: { xs: '0.8rem', sm: '1rem' },
                      display: { xs: 'none', sm: 'table-cell' },
                    }}
                  >
                    Notes
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'common.white',
                      fontSize: { xs: '0.8rem', sm: '1rem' },
                    }}
                    colSpan={3}
                  >
                    EDIT / REMOVE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inProgressVehicles.map((vehicle, index) => {
                  // Find the project that corresponds to the vehicle's projectId
                  const project = projects.find(
                    (project) => project.projectId === vehicle.projectId
                  );

                  return (
                    <TableRow key={index} style={getStatusStyle(vehicle.status)}>
                      <TableCell>{vehicle.vehicleNumber}</TableCell>
                      {/* Display the Project-Location */}
                      <TableCell>{project ? project.location : 'N/A'}</TableCell>
                      <TableCell>{vehicle.status}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                        {vehicle.notes}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => handleEditVehicle(vehicle.vehicleId)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => handleRemoveFromQueue(vehicle)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {contractorDetails && (
        <InspectionForm
          mode={inspectionMode}
          open={openInspectionForm}
          onClose={() => setOpenInspectionForm(false)}
          initialVehicle={selectedVehicleForInspection as Vehicle}
          onSave={handleSaveFormData}
          contractorDetails={effectiveContractorDetails as Contractor}
          onReview={handleReviewInspectionForm}
        />
      )}
      {isSiteLead && renderVehicleApprovalTable()}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ContractorDashboard;
