import React, { useState, useEffect } from 'react';
import { Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, Dialog, TextField, Tab, Tabs, Paper, Box, Alert } from '@mui/material';
import Vehicle from '../../Models/Vehicle';

const columnStyle = { width: '16%' }; // Adjust the width as needed

interface VehicleComponentProps {
    show: boolean;
    onClose: () => void;
    onVehiclesChange: (vehicles: Vehicle[]) => void;
    initialVehicles: Vehicle[]; // new prop for initial vehicles
}

export default function VehicleComponent({ show, onClose, onVehiclesChange, initialVehicles }: VehicleComponentProps) {

    const [vehicles, setVehicles] = useState<Vehicle[]>(initialVehicles);
    const [vehiclesGroupedByConfig, setVehiclesGroupedByConfig] = useState<{ [key: string]: Vehicle[] }>({});
    const [activeTab, setActiveTab] = useState(0);
    const [duplicateVehicleNumber, setDuplicateVehicleNumber] = useState(false);
    
    useEffect(() => {
        setVehicles(initialVehicles);
    }, [initialVehicles]);

    useEffect(() => {
        // Get all vehicles

        setVehiclesGroupedByConfig(
            vehicles.reduce((groupedVehicles: { [key: string]: Vehicle[] }, vehicle) => {
                const configId = vehicle.configurationId || 'Unknown';
                groupedVehicles[configId] = groupedVehicles[configId] || [];
                groupedVehicles[configId].push(vehicle);
                setDuplicateVehicleNumber(checkForDuplicateVehicleNumbers(vehicles));
                return groupedVehicles;
            }, {})
        );
    }, [vehicles]);

    
    const handleVehicleNumberChange = (vehicleId: number, newVehicleNumber: string) => {
        const updatedVehicles = vehicles.map(vehicle =>
            vehicle.vehicleId === vehicleId ? { ...vehicle, vehicleNumber: newVehicleNumber } : vehicle
        );
        setVehicles(updatedVehicles);
        setDuplicateVehicleNumber(checkForDuplicateVehicleNumbers(updatedVehicles));
    };
    
    const checkForDuplicateVehicleNumbers = (updatedVehicles: Vehicle[]) => {
        const vehicleNumbers = new Set();
        for (let vehicle of updatedVehicles) {
            // Skip null or empty vehicle numbers
            if (!vehicle.vehicleNumber || vehicle.vehicleNumber.trim() === '') {
                continue;
            }
    
            if (vehicleNumbers.has(vehicle.vehicleNumber)) {
                return true; // Found a duplicate
            }
            vehicleNumbers.add(vehicle.vehicleNumber);
        }
        return false; // No duplicates found
    };

    const handleClose = () => {
        onVehiclesChange(vehicles); // Pass the updated vehicles back to the parent component
        onClose(); // Close the modal
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

     const renderVehicleTable = (vehicles: Vehicle[]) => (
        <Paper elevation={2} sx={{ mt: 2, mb: 2, overflowX: 'auto' }}>
            <Table sx={{ minWidth: 750 }}>
                <TableHead>
                    <TableRow>
                        {/* Table Headers */}
                    <TableCell align="center" style={columnStyle}>Vehicle Number</TableCell>
                    <TableCell align="center" style={columnStyle}>Make</TableCell>
                    <TableCell align="center" style={columnStyle}>Model</TableCell>
                    <TableCell align="center" style={columnStyle}>Vin</TableCell>
                    <TableCell align="center" style={columnStyle}>Status</TableCell>
                    <TableCell align="center" style={columnStyle}>Notes</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {vehicles.map((vehicle) => (
                    <TableRow key={vehicle.vehicleId}>
                        {/* Vehicle Number */}
                        <TableCell style={columnStyle}>
                            <TextField
                                value={vehicle.vehicleNumber}
                                onChange={(e) => handleVehicleNumberChange(vehicle.vehicleId, e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </TableCell>

                        <TableCell style={columnStyle}>
                            <TextField
                                value={vehicle.make}
                                onChange={(e) => setVehicles((prevVehicles) => prevVehicles.map(v => v.vehicleId === vehicle.vehicleId ? { ...v, make: e.target.value } : v))}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </TableCell>
                        <TableCell style={columnStyle}>
                            <TextField
                                value={vehicle.model}
                                onChange={(e) => setVehicles((prevVehicles) => prevVehicles.map(v => v.vehicleId === vehicle.vehicleId ? { ...v, model: e.target.value } : v))}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </TableCell>
                        <TableCell style={columnStyle}>
                            <TextField
                                value={vehicle.vin}
                                onChange={(e) => setVehicles((prevVehicles) => prevVehicles.map(v => v.vehicleId === vehicle.vehicleId ? { ...v, vin: e.target.value } : v))}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </TableCell>
                        <TableCell align='center' style={columnStyle}>{vehicle.status}</TableCell>
                        {/* Notes */}
                        <TableCell style={columnStyle}>
                            <TextField
                                value={vehicle.notes}
                                onChange={(e) => setVehicles((prevVehicles) => prevVehicles.map(v => v.vehicleId === vehicle.vehicleId ? { ...v, notes: e.target.value } : v))}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
         </Paper>
    );

    return (
        <Dialog open={show} fullWidth maxWidth="lg">
            <Box sx={{ padding: '20px' }}>
                <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                    Vehicle List
                </Typography>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="Vehicle Configuration Tabs" variant="scrollable" scrollButtons="auto">
                    {Object.entries(vehiclesGroupedByConfig).map(([configId, vehicles], index) => (
                        <Tab label={`Configuration ${configId} (${vehicles.length})`} key={index} />
                    ))}
                </Tabs>
                {duplicateVehicleNumber && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        Duplicate vehicle numbers detected. Please correct them.
                    </Alert>
                )}
                <Box sx={{ maxHeight: '500px', overflowY: 'auto', mt: '20px' }}>
                    {Object.entries(vehiclesGroupedByConfig).map(([configId, vehicles], index) => (
                        <div role="tabpanel" hidden={activeTab !== index} key={configId}>
                            {activeTab === index && renderVehicleTable(vehicles)}
                        </div>
                    ))}
               </Box>
            </Box>
            <Button onClick={handleClose} sx={{ margin: '20px', float: 'right' }}>Close</Button>
        </Dialog>

    );
}