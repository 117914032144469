import React, { useEffect, useState } from 'react';
import {
    Paper,
    styled, TableCell,
    TextField, Grid, Button, Divider
} from '@mui/material';
import EquipmentType from '../../Models/EquipmentType';
import QuoteConfiguration from '../../Models/QuoteConfiguration';
import EquipmentTable from '../QuoteEquipmentConfiguration/EquipmentTable';
import PricingDetails from '../QuoteEquipmentConfiguration/PricingDetails';
import VehicleTypeSelector from '../QuoteEquipmentConfiguration/VehicleTypeSelector';

interface Props {
    equipmentTypes: EquipmentType[];
    currentConfig: QuoteConfiguration;
    onConfigurationChange: (newConfig: QuoteConfiguration) => void;
    hasUnsavedChanges: (hasChanges: boolean) => void;
}

const QuoteEquipmentComponent: React.FC<Props> = ({ equipmentTypes, currentConfig, onConfigurationChange, hasUnsavedChanges }) => {
    const [installCount, setCount] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [contractorPayPercentage, setContractorPayPercentage] = useState<number>(27);
    const [notes, setNotes] = useState<string>(currentConfig.notes || "");
    const [currentEquipment, setCurrentEquipment] = useState(() => {
        const equipmentData: { [key: number]: { price: number, equipmentId: number, configurationId: number, quantity: number } } = {};
        currentConfig.quoteEquipment?.forEach(equip => {
            equipmentData[equip.equipmentTypeId!] = {
                price: equip.price!,
                equipmentId: equip.equipmentId!,
                configurationId: equip.configurationId!,
                quantity: 1 // Initialize quantity
            };
        });
        return equipmentData;
    });
    // State for new custom equipment
    const [newEquipmentName, setNewEquipmentName] = useState('');
    const [newEquipmentPrice, setNewEquipmentPrice] = useState(0);
    // State for custom equipment added during this session
    const [customEquipment, setCustomEquipment] = useState<EquipmentType[]>([]);

    // Initialize state with default prices from equipmentTypes
    const [currentPrices, setCurrentPrices] = useState(() => {
        const equipmentPrices: { [key: number]: number } = {};
        currentConfig.quoteEquipment?.forEach(equip => {
            equipmentPrices[equip.equipmentTypeId!] = equip.price!;
        });

        setContractorPayPercentage(currentConfig.contractorPayPercentage!);
        setCount(currentConfig.installCount!);
        return equipmentPrices;
    });

    const [currentQuantities, setCurrentQuantities] = useState(() => {
        const equipmentQuantities: { [key: number]: number } = {};
        currentConfig.quoteEquipment?.forEach(equip => {
            // Initialize each equipment's quantity. Assuming you have a quantity field in your data model.
            // If not, you might initialize all quantities to 1 or another default value.
            equipmentQuantities[equip.equipmentTypeId!] = equip.quantity || 1;
        });

        return equipmentQuantities;
    });

    const distinctVehicleTypes = Array.from(new Set(equipmentTypes.map(e => e.vehicleType).filter(type => type)));
    const hasVehicleTypes = distinctVehicleTypes.length > 0;

    // Initialize the selectedVehicleType from the currentConfig
    const [selectedVehicleType, setSelectedVehicleType] = useState(currentConfig.selectedVehicleType || (hasVehicleTypes ? distinctVehicleTypes[0] : ''));


    // Update the total when prices or installCount change
    useEffect(() => {
        setTotal(calculateTotalPrice() * installCount);
        hasUnsavedChanges(true);
    }, [installCount, contractorPayPercentage, notes, currentEquipment, currentPrices, selectedVehicleType, currentQuantities]);

    // Handle vehicle type change
    const handleVehicleTypeChange = (newVehicleType: string | undefined) => {
        setSelectedVehicleType(newVehicleType!);

        const newPrices: { [key: number]: number } = {};
        combinedEquipmentTypes()
            .filter(e => e.vehicleType === newVehicleType || !e.vehicleType)
            .forEach(e => {
                newPrices[e.equipmentTypeId] = currentPrices[e.equipmentTypeId] ?? e.price ?? 0;
            });

        setCurrentPrices(newPrices);
    };

    // Combine default and custom equipment types
    const combinedEquipmentTypes = () => {
        const combinedTypes = [...equipmentTypes];

        // Include custom equipment from currentConfig
        currentConfig.quoteEquipment.forEach(equip => {
            if (!combinedTypes.some(e => e.equipmentTypeId === equip.equipmentTypeId)) {
                const customEquipType: EquipmentType = {
                    equipmentTypeId: equip.equipmentTypeId,
                    name: equip.name,
                    price: equip.price || 0,
                    vehicleType: selectedVehicleType, // Assuming QuoteEquipment has a vehicleType field
                    // Add default values or map from equip for missing fields
                    organizationId: 0,
                    description: ''
                };
                combinedTypes.push(customEquipType);
            }
        });

        // Include custom equipment added during this session
        customEquipment.forEach(equip => {
            if (!combinedTypes.some(e => e.equipmentTypeId === equip.equipmentTypeId)) {
                combinedTypes.push(equip);
            }
        });

        return combinedTypes;
    };

    // Filter the combined equipment types based on the selected vehicle type, or use all if no vehicle types are available
    const filteredCombinedEquipmentTypes = hasVehicleTypes
        ? combinedEquipmentTypes().filter(type => type.vehicleType === selectedVehicleType)
        : combinedEquipmentTypes();

    const handlePriceChange = (equipmentTypeId: number, price: number) => {
        setCurrentPrices((prevPrices) => ({ ...prevPrices, [equipmentTypeId]: price }));
    };

const calculateTotalPrice = () => {
    // Filter equipment based on the selected vehicle type
    const relevantEquipment = combinedEquipmentTypes().filter(equip => 
        !selectedVehicleType || equip.vehicleType === selectedVehicleType || !equip.vehicleType
    );

    // Calculate total price based on filtered equipment
    return relevantEquipment.reduce((sum, equip) => {
        const price = currentPrices[equip.equipmentTypeId] || 0;
        const quantity = currentQuantities[equip.equipmentTypeId] || 1;
        return sum + (price * quantity);
    }, 0);
};

    const handleSaveChanges = () => {
        const contractorPayTotal = (total / installCount) * contractorPayPercentage / 100;

        // Get all relevant equipment types, including custom ones
        const relevantEquipmentTypes = combinedEquipmentTypes();

        const updatedConfig = {
            ...currentConfig,
            selectedVehicleType: selectedVehicleType,
            quoteEquipment: relevantEquipmentTypes
                .filter(equip => equip.vehicleType === selectedVehicleType || !equip.vehicleType) // Filter relevant equipment types
                .map(equip => {
                    const price = currentPrices[equip.equipmentTypeId] || 0;
                    return {
                        equipmentId: currentEquipment[equip.equipmentTypeId]?.equipmentId || 0,
                        configurationId: currentConfig.configurationId!,
                        equipmentTypeId: equip.equipmentTypeId,
                        isChecked: price > 0,
                        price: price,
                        name: equip.name,
                        quantity: currentQuantities[equip.equipmentTypeId] || 1,
                    };
                }),
            contractorPerInstallPay: parseFloat(contractorPayTotal.toFixed(0)),
            contractorPayPercentage: parseFloat(contractorPayPercentage.toFixed(2)),
            installCount: installCount,
            notes: notes,
        };
        console.log("Updated Configuration", updatedConfig);
        onConfigurationChange(updatedConfig);
        hasUnsavedChanges(false);
    };

    const handleAddCustomEquipment = () => {
        if (!newEquipmentName.trim()) return;

        const newEquipment = {
            equipmentTypeId: generateUniqueEquipmentTypeId(),
            name: newEquipmentName,
            price: newEquipmentPrice,
            vehicleType: selectedVehicleType,
            description: '',
            organizationId: 0,
            quantity: 1
        };

        setCustomEquipment(prev => [...prev, newEquipment]);
        setCurrentPrices(prev => ({ ...prev, [newEquipment.equipmentTypeId]: newEquipmentPrice }));

        setNewEquipmentName('');
        setNewEquipmentPrice(0);

        hasUnsavedChanges(true);
    };

    const generateUniqueEquipmentTypeId = () => {
        // Generate a random number greater than or equal to 200
        let randomId = Math.floor(Math.random() * 1000000) + 200; // Adjust the multiplier for a wider range

        // Ensure the generated ID is unique
        while (filteredCombinedEquipmentTypes.some(e => e.equipmentTypeId === randomId)) {
            randomId = Math.floor(Math.random() * 1000000) + 200;
        }

        return randomId;
    };

    const handleQuantityChange = (equipmentTypeId: number, quantity: number) => {
        setCurrentQuantities(prevQuantities => ({
            ...prevQuantities,
            [equipmentTypeId]: quantity
        }));
    };

    return (
        <Paper elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
            {hasVehicleTypes && (
                <VehicleTypeSelector
                    vehicleTypes={distinctVehicleTypes.filter(type => type !== undefined) as string[]}
                    selectedVehicleType={selectedVehicleType ?? ''}
                    onVehicleTypeChange={handleVehicleTypeChange}
                />
            )}
            <Divider style={{ margin: '16px 0' }} />
            {/* UI for adding custom equipment */}
            <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="New Equipment Name"
                        value={newEquipmentName}
                        onChange={(e) => setNewEquipmentName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Price"
                        type="number"
                        value={newEquipmentPrice}
                        onChange={(e) => setNewEquipmentPrice(parseFloat(e.target.value))}
                    />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddCustomEquipment}
                    >
                        Add Equipment
                    </Button>
                </Grid>
            </Grid>
            <EquipmentTable
                equipmentTypes={filteredCombinedEquipmentTypes}
                currentPrices={currentPrices}
                onPriceChange={handlePriceChange}
                currentQuantities={currentQuantities}
                onQuantityChange={handleQuantityChange}
            />
            <PricingDetails
                installCount={installCount}
                total={total}
                contractorPayPercentage={contractorPayPercentage}
                notes={notes}
                onInstallCountChange={setCount}
                onContractorPayPercentageChange={setContractorPayPercentage}
                onNotesChange={setNotes}
            />
            <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
                <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
            </Grid>
        </Paper>
    );
};

export default QuoteEquipmentComponent;
