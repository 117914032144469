import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    TextField,
    Grid,
    Box,
    IconButton,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Paper,
    SelectChangeEvent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import Webcam from 'react-webcam';
import { BrowserMultiFormatReader } from '@zxing/library';
import Vehicle from '../../Models/Vehicle';
import Contractor from '../../Models/Contractor';
import InspectionFormObject from '../../Models/InspectionForm';
import getImageSrc from '../../Utilities/GetImageSrc';
import ValidationErrors from '../../Models/ValidationErrors';

interface VehicleFormSectionProps {
    id: string;
    formState: InspectionFormObject;
    onFieldChange: (field: keyof InspectionFormObject, value: any) => void;
    vehicle: Vehicle; // Ensure vehicle is not optional
    onVehicleChange: (field: keyof Vehicle, value: any) => void;
    contractors: Contractor[];
    errors: ValidationErrors;
}

const VehicleFormSection: React.FC<VehicleFormSectionProps> = ({
    id,
    formState,
    onFieldChange,
    vehicle,
    onVehicleChange,
    contractors,
    errors
}) => {
    const [selectedContractor, setSelectedContractor] = useState('');
    const [scannerOpen, setScannerOpen] = useState(false);
    const [scanningResult, setScanningResult] = useState<string | null>(null);
    const webcamRef = useRef<Webcam>(null);
    const codeReader = useRef(new BrowserMultiFormatReader());

    useEffect(() => {
        if (formState.assistingContractorId) {
            setSelectedContractor(formState.assistingContractorId.toString() || '');
        }
    }, [formState.assistingContractorId]);

    const handleInputChange = (field: keyof Vehicle) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onVehicleChange(field, event.target.value);
    };

    const handleFileChange = (field: keyof InspectionFormObject) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            onFieldChange(field, event.target.files[0]);
        }
    };

    const handleContractorChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        onFieldChange('assistingContractorId', value);  // Directly update formState
    };
    const handleScan = useCallback(() => {
        if (!webcamRef.current) return;

        const capture = async () => {
            const imageSrc = webcamRef.current?.getCanvas();
            if (imageSrc) {
                try {
                    const imageElement = document.createElement('img');
                    imageElement.src = imageSrc.toDataURL();
                    const result = await codeReader.current.decodeFromImageElement(imageElement);
                    setScanningResult(result.getText());
                    onVehicleChange('vin', result.getText());
                    setScannerOpen(false);
                } catch (err) {
                    console.log('No QR code detected:', err);
                }
            }
        };

        const intervalId = setInterval(capture, 500);
        return () => clearInterval(intervalId);
    }, [onVehicleChange]);

    useEffect(() => {
        if (scannerOpen) {
            handleScan();
        }
    }, [scannerOpen, handleScan]);

    return (
        <Paper id={id} variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" gap={2}>
                        <TextField
                            label="Make"
                            fullWidth
                            value={vehicle?.make || ''}
                            onChange={handleInputChange('make')}
                            margin="normal"
                            error={Boolean(errors.make)}
                            helperText={errors.make}
                        />
                        <TextField
                            label="Model"
                            fullWidth
                            value={vehicle?.model || ''}
                            onChange={handleInputChange('model')}
                            margin="normal"
                            error={Boolean(errors.model)}
                            helperText={errors.model}
                        />
                        <TextField
                            label="Year"
                            fullWidth
                            type="number"
                            value={vehicle?.year?.toString() || ''}
                            onChange={handleInputChange('year')}
                            margin="normal"
                            error={Boolean(errors.year)}
                            helperText={errors.year}
                            inputProps={{ min: 1900, max: new Date().getFullYear() }}
                        />
                    </Box>
                    <TextField
                        label="Vehicle Notes"
                        fullWidth
                        multiline
                        rows={4}
                        value={vehicle?.notes || ''}
                        onChange={handleInputChange('notes')}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="contractor-select-label">Assisting Contractor</InputLabel>
                        <Select
                            labelId="contractor-select-label"
                            id="contractor-select"
                            value={formState.assistingContractorId?.toString() || ''}  // Tie to formState
                            label="Assisting Contractor"
                            onChange={handleContractorChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {contractors.map((contractor) => (
                                <MenuItem key={contractor.contractorId} value={contractor.contractorId}>
                                    {contractor.firstName} {contractor.lastName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
  label="Vehicle Number"
  fullWidth
  value={vehicle?.vehicleNumber || ''}
  onChange={handleInputChange('vehicleNumber')}
  margin="normal"
  error={Boolean(errors.vehicleNumber)}
  helperText={errors.vehicleNumber}
/>
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="vehicle-id-image-input">
                            <IconButton color="primary" component="span">
                                <PhotoCamera />
                            </IconButton>
                        </label>
                        <Typography variant="body2" sx={{ ml: 1 }}>Upload Vehicle Number Image</Typography>
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            id="vehicle-id-image-input"
                            onChange={handleFileChange('vehicleIdImage')}
                        />
                    </Box>
                    {getImageSrc(formState.vehicleIdImage, formState.vehicleIdImagePath) && (
                        <img
                            src={getImageSrc(formState.vehicleIdImage, formState.vehicleIdImagePath) ?? ''}
                            alt="Vehicle ID Image"
                            style={{ maxWidth: '100%', maxHeight: 200 }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="VIN"
                        fullWidth
                        value={vehicle?.vin || scanningResult || ''}
                        onChange={handleInputChange('vin')}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="scan barcode"
                                        onClick={() => setScannerOpen(true)}
                                        edge="end"
                                    >
                                        <PhotoCamera color="primary" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(errors.vin)}
                        helperText={errors.vin}
                    />
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="vin-image-input">
                            <IconButton color="primary" component="span">
                                <PhotoCamera />
                            </IconButton>
                        </label>
                        <Typography variant="body2" sx={{ ml: 1 }}>Upload VIN Image</Typography>
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            id="vin-image-input"
                            onChange={handleFileChange('vinImage')}
                        />
                    </Box>
                    {getImageSrc(formState.vinImage, formState.vinImagePath) && (
                        <img
                            src={getImageSrc(formState.vinImage, formState.vinImagePath) ?? ''}
                            alt="VIN Image"
                            style={{ maxWidth: '100%', maxHeight: 200 }}
                        />
                    )}
                </Grid>
            </Grid>

            <Dialog open={scannerOpen} onClose={() => setScannerOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Scan VIN</DialogTitle>
                <DialogContent>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        height={400}
                        videoConstraints={{
                            facingMode: 'environment'
                        }}
                    />
                    {scanningResult && (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            Scanned VIN: {scanningResult}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setScannerOpen(false)} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default VehicleFormSection;
