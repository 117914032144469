import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Grid, Button, Box, Typography, List, Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Checkbox, FormControl, InputLabel, MenuItem, Select, Card, Accordion, AccordionDetails, AccordionSummary, SelectChangeEvent, Alert, Snackbar, AlertColor } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { Cancel as CancelIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import Project from '../../Models/Project';
import EnhancedFile from '../../Models/EnhancedFile';
import Contractor from '../../Models/Contractor';
import ContractorService from '../../Services/ContractorService';
import Employee from '../../Models/Employee';


// Additional styling for the button and contractor section
const styles = {
    contractorCard: {
        p: 2,
        mb: 3,
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
    },
    generateButton: {
        mt: 2,
        bgcolor: '#4CAF50', // Green color
        color: 'white',
        '&:hover': {
            bgcolor: '#388E3C', // Darker shade for hover state
        },
        fontWeight: 'bold'
    },
    contractorSelect: {
        mt: 1,
        mb: 2
    },
    title: {
        fontWeight: 'bold',
        color: '#333',
        mb: 2
    }
};

type ProjectDialogProps = {
    open: boolean;
    onClose: () => void;
    project: Project | null;
    onDateChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: 'startDate' | 'endDate') => void;
    onVehicleModalToggle: () => void;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSave: () => void;
    files: EnhancedFile[];
    previewFile: EnhancedFile | null;
    onPreviewClose: () => void;
    onFilePreview: (file: EnhancedFile) => void;
    contractors: Contractor[];
    employees: Employee[];
    contractorService: ContractorService;
    onProjectChange: (field: string, value: any) => void;
    isModified: boolean;
};

const ProjectDialog: React.FC<ProjectDialogProps> = ({
    open,
    onClose,
    project,
    onDateChange,
    onVehicleModalToggle,
    onFileChange,
    onSave,
    files,
    previewFile,
    onPreviewClose,
    onFilePreview,
    contractors,
    employees,
    contractorService,
    onProjectChange,
    isModified,
}) => {
    const [newNoteContent, setNewNoteContent] = useState('');
    const [selectedContractors, setSelectedContractors] = useState<number[]>([]);
    const [selectedSiteLeads, setSelectedSiteLeads] = useState<number[]>([]);
    const [selectedProjectManagerId, setSelectedProjectManagerId] = useState<number | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    useEffect(() => {
        if (project) {
            const contractorIdsArray = project.contractorIds ? project.contractorIds.split(',').map(Number) : [];
            const siteLeadIdsArray = project.siteLeadIds ? project.siteLeadIds.split(',').map(Number) : [];
            setSelectedContractors(contractorIdsArray);
            setSelectedSiteLeads(siteLeadIdsArray);
            setSelectedProjectManagerId(project.projectManagerId);
        }
    }, [project]);

    // Filter out the site lead from the contractors list
    const filteredContractors = contractors.filter(c => !selectedSiteLeads.includes(c.contractorId));

    if (!project) return null;

    const handleContractorsChange = (event: SelectChangeEvent<number[]>) => {
        const value = event.target.value;
        const contractorIds = typeof value === 'string' ? value.split(',').map(Number) : value;
        setSelectedContractors(contractorIds);
        if (project) {
            project.contractorIds = contractorIds.join(',');
        }
    };

    const handleSiteLeadsChange = (event: SelectChangeEvent<number[]>) => {
        const value = event.target.value;
        const siteLeadIds = typeof value === 'string' ? value.split(',').map(Number) : value;
        setSelectedSiteLeads(siteLeadIds);
        if (project) {
            project.siteLeadIds = siteLeadIds.join(',');
        }
    };

    //handle project manager change
    const handleProjectManagerChange = (event: SelectChangeEvent<number>) => {
        setSelectedProjectManagerId(Number(event.target.value));
        if (project) {
            project.projectManagerId = Number(event.target.value);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onProjectChange(event.target.name, event.target.value);
    };

    const handleCancel = () => {
        if (isModified) {
            if (window.confirm("You have unsaved changes. Are you sure you want to cancel?")) {
                onClose(); // handleClose should reset isModified and close the dialog
            }
        } else {
            onClose(); // If no changes were made, just close the dialog
        }
    };

    const handleAddNote = () => {
        if (!newNoteContent.trim()) return; // Prevent adding empty notes

        const newNote = {
            content: newNoteContent,
            timeStamp: new Date().toISOString() // Current timestamp
        };

        const updatedNotes = project.notes ? [...project.notes, newNote] : [newNote];
        onProjectChange('notes', updatedNotes);

        setNewNoteContent(''); // Clear the input field after adding the note
    };

    // Function to handle Exhibit A generation
    const handleGenerateExhibitA = async () => {
        if (!project) {
            setSnackbarMessage('No project selected');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
            // Split the contractorIds string into an array
            const contractorIdsArray = project.contractorIds.split(',').map(id => parseInt(id));
            const response = await contractorService.generateExhibitA(project.projectId, contractorIdsArray);

            setSnackbarMessage('Exhibit A documents generated successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

        } catch (error) {
            console.error('Error generating Exhibit A:', error);
            setSnackbarMessage('Failed to generate Exhibit A documents');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    // Function to handle Exhibit A generation for Site Leads
    const handleGenerateExhibitAForSiteLeads = async () => {
        if (!project) {
            setSnackbarMessage('No project selected');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
            // Split the contractorIds string into an array
            const contractorIdsArray = project.siteLeadIds.split(',').map(id => parseInt(id));

            const response = await contractorService.generateExhibitASiteLeads(project.projectId, contractorIdsArray);

            // Assume the response is handled correctly
            setSnackbarMessage('Exhibit A documents generated successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error generating Exhibit A for site leads:', error);
            setSnackbarMessage('Failed to generate Exhibit A documents for site leads');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };


    return (
        <Dialog open={open}     onClose={(event, reason) => {
            // Prevents closing if the reason is clicking outside or pressing ESC
            if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
                return; // Do nothing
            }
            // You might still want to call onClose under specific conditions here
        }} fullWidth maxWidth="md">
            <DialogTitle sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}>
                Edit Project {project.projectNumber}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ my: 2 }}>

                    {/* Project Details Card */}
                    <Card variant="outlined" sx={{ p: 2, mb: 3, bgcolor: 'background.paper' }}>
                        <Typography fontWeight="bold" variant="h6" gutterBottom sx={{ mb: 3 }}>Project Details</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth label="Location" name="location" value={project.location} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth label="Status" name="status" value={project.status} onChange={handleChange} />
                            </Grid>
                        </Grid>
                        <Button variant="outlined" color="primary" startIcon={<DriveEtaIcon />} onClick={onVehicleModalToggle} sx={{ mt: 2 }}>
                            Vehicle List ({project.vehicles?.length || 0})
                        </Button>
                    </Card>

                    {/* Contractors Card for Exhibit A */}
                    <Card variant="outlined" sx={styles.contractorCard}>
                        {/* Site Lead Dropdown */}
                        <FormControl fullWidth margin="normal" sx={{ ...styles.contractorSelect, mb: 1 }}>
                            <InputLabel id="site-lead-select-label" style={{ position: 'absolute', top: -8 }}>Site Lead</InputLabel>
                            <Select
                                labelId="site-lead-select-label"
                                id="site-lead-select"
                                multiple
                                value={selectedSiteLeads}
                                onChange={handleSiteLeadsChange}
                                renderValue={(selected) => selected.map(id => contractors.find(c => c.contractorId === id)?.firstName).join(', ')}
                            >
                                {contractors.map((contractor) => (
                                    <MenuItem key={contractor.contractorId} value={contractor.contractorId}>
                                        <Checkbox checked={selectedSiteLeads.includes(contractor.contractorId)} />
                                        {contractor.firstName} {contractor.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button
                            variant="contained"
                            onClick={handleGenerateExhibitAForSiteLeads}
                            sx={styles.generateButton}
                        >
                            Generate ExhibitA Site Lead
                        </Button>
                        {/* Contractors Dropdown */}
                        <FormControl fullWidth margin="normal" sx={{ ...styles.contractorSelect, mb: 1, mt: 4 }}>
                            <InputLabel id="site-lead-select-label" style={{ position: 'absolute', top: -8 }}>Contractors</InputLabel>
                            <Select
                                labelId="contractor-multiple-select-label"
                                id="contractor-multiple-select"
                                multiple
                                value={selectedContractors}
                                onChange={handleContractorsChange}
                                renderValue={(selected) => selected.map(id => contractors.find(c => c.contractorId === id)?.firstName).join(', ')}
                            >
                                {filteredContractors.map((contractor) => (
                                    <MenuItem key={contractor.contractorId} value={contractor.contractorId}>
                                        <Checkbox checked={selectedContractors.includes(contractor.contractorId)} />
                                        {contractor.firstName} {contractor.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button
                            variant="contained"
                            onClick={handleGenerateExhibitA}
                            sx={styles.generateButton}
                        >
                            Generate ExhibitA Contractor
                        </Button>
                    </Card>

                    {/* Project Manager Card */}
                    <Card variant="outlined" sx={styles.contractorCard}>
                        <FormControl fullWidth margin="normal" sx={{ ...styles.contractorSelect, mb: 2 }}>
                            <InputLabel id="project-manager-select-label" style={{ position: 'absolute', top: -8 }}>Project Manager</InputLabel>
                            <Select
                                labelId="project-manager-select-label"
                                id="project-manager-select"
                                value={selectedProjectManagerId || ''}
                                onChange={handleProjectManagerChange}
                            >
                                {employees.map((manager) => (
                                    <MenuItem key={manager.employeeId} value={manager.employeeId}>
                                        {manager.firstName} {manager.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Card>

                    {previewFile && previewFile.type.startsWith('image/') && (
                        <Dialog open={!!previewFile} onClose={onPreviewClose}>
                            <img src={previewFile.presignedUrl || ''} alt={previewFile.fileName} style={{ width: '100%', height: 'auto' }} />
                        </Dialog>
                    )}

                    {/* Notes Section */}
                    <Card variant="outlined" sx={{ p: 3, mb: 3, bgcolor: 'background.paper', boxShadow: 1 }}>
                        <Typography fontWeight="bold" variant="h6" gutterBottom >
                            Notes
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm>
                                <TextField
                                    fullWidth
                                    label="Add a new note..."
                                    multiline
                                    rows={2}
                                    value={newNoteContent}
                                    onChange={(e) => setNewNoteContent(e.target.value)}
                                    placeholder="Write your note here..."
                                    sx={{ borderRadius: 1 }}
                                />
                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Button variant="contained" color="primary" onClick={handleAddNote}>
                                    Add Note
                                </Button>
                            </Grid>
                        </Grid>
                        <Accordion sx={{ mt: 3, bgcolor: 'action.hover' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ color: 'black' }}>View Notes History</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                                <List>
                                    {project.notes?.map((note, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                my: 0.5,
                                                p: 2,
                                                borderRadius: '4px',
                                                bgcolor: 'lightblue',
                                            }}>
                                            <ListItemText
                                                primary={note.content}
                                                secondary={new Date(note.timeStamp).toLocaleString()}
                                                primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'medium' }}
                                                secondaryTypographyProps={{ fontSize: '0.75rem' }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Card>


                    {/* File Upload and List */}
                    <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
                        <Button variant="contained" color="primary" startIcon={<CloudUploadIcon />} component="label">
                            Upload Files
                            <input type="file" multiple hidden onChange={onFileChange} />
                        </Button>

                        <List sx={{ mt: 2, maxHeight: '300px', overflowY: 'auto' }}>
                            {files.map(file => (
                                <ListItem key={file.s3Key} button onClick={() => onFilePreview(file)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={file.fileName} secondary={`${(file.size / 1024).toFixed(2)} KB`} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="download" href={file.presignedUrl || ''} target="_blank">
                                            <DownloadIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Card>

                    {/* Date Selection */}
                    <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    defaultValue={project.startDate.toISOString().split('T')[0]}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onDateChange(e, 'startDate')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    defaultValue={project.endDate.toISOString().split('T')[0]}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onDateChange(e, 'endDate')}
                                />
                            </Grid>
                        </Grid>
                    </Card>

                    {/* Action Buttons and Last Updated */}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                            Last Updated: {project.lastUpdated.toLocaleDateString()}
                        </Typography>
                        <Box>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCancel}
                                startIcon={<CancelIcon />}

                                sx={{ mr: 1 }} // Adding right margin to the Save button
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSave}
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity as AlertColor} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default ProjectDialog;
