import { useAuth0 } from '@auth0/auth0-react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import signInBackground from '../Images/taskForgeConcept2.webp';

function Profile() {
  const { isAuthenticated, user, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const namespace = process.env.REACT_APP_BASE_URL;
  const roles = user?.[`${namespace}/roles`] ?? [];

  const roleBasedNavigation = (roles: string[]) => {
    let navigationOptions = [];

    if (roles.includes('Admin')) {
      navigationOptions.push({ name: 'Quote Dashboard', path: '/QuoteDashboard' });
      navigationOptions.push({ name: 'Project Dashboard', path: '/ProjectDashboard' });
      navigationOptions.push({ name: 'Admin Approval Dashboard', path: '/AdminApprovalDashboard' });
    }
    if (roles.includes('contractor')) {
      navigationOptions.push({ name: 'Contractor Dashboard', path: '/contractor' });
    }
    // Add more roles and navigation options as needed

    return navigationOptions;
  };

  const navigationOptions = roleBasedNavigation(roles);

  return (
    <Box
    sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${signInBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4  }} >
        {isAuthenticated && (
          <Grid container spacing={3} direction="column" alignItems="center">
            <Typography variant="h5" gutterBottom component="div">
              Welcome to Your Profile 
            </Typography>
            <Avatar alt={user?.email} src={user?.picture} sx={{ width: 120, height: 120, mb: 2 }} />
            <TextField id="email" label="Email" value={user?.email} variant="outlined" fullWidth disabled sx={{ mb: 2 }} />
            <Box sx={{ mt: 2, mb: 2, width: '100%' }}>
              <Typography variant="subtitle1">
                Roles: {roles.join(', ') || 'No specific roles assigned.'}
              </Typography>
            </Box>
            <Typography variant="subtitle1" gutterBottom>
              Based on your roles, you have access to the following areas of our application:
            </Typography>
            {navigationOptions.length > 0 ? (
              navigationOptions.map((option) => (
                <Button
                  key={option.name}
                  component={RouterLink}
                  to={option.path}
                  variant="contained"
                  sx={{ margin: '5px', background: '#1976d2' }}
                >
                  {option.name}
                </Button>
              ))
            ) : (
              <Typography variant="body2">
                No specific areas to navigate to. Please contact the administrator if you believe this is an error.
              </Typography>
            )}
          </Grid>
        )}
      </Paper>
    </Container>
  </Box>
  );
}

export default Profile;
