import React from 'react';
import { Paper, Typography, TextField, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface ReviewSectionProps {
    reviewStatus: string;
    comments: string;
    reviewType: 'Site Lead' | 'Project Manager' | 'Admin'; // New prop to specify the type of review
    onChangeStatus: (type: string, status: string) => void; // Adjusted to include type
    onChangeComments: (type: string, comments: string) => void; // Adjusted to include type
}

export const ReviewSection: React.FC<ReviewSectionProps> = ({
    reviewStatus,
    comments,
    reviewType,
    onChangeStatus,
    onChangeComments
}) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mt: 1, mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <RadioGroup
                    row
                    value={reviewStatus} // This should correctly reflect the state
                    onChange={(e) => onChangeStatus(reviewType, e.target.value)}
                >
                    <FormControlLabel value="Approved" control={<Radio />} label="Approve" />
                    <FormControlLabel value="Declined" control={<Radio />} label="Decline" />
                </RadioGroup>
            </Box>
            <TextField
                fullWidth
                label="Comments"
                multiline
                rows={4}
                value={comments}
                onChange={(e) => onChangeComments(reviewType, e.target.value)}
                variant="outlined"
                InputLabelProps={{
                    shrink: Boolean(comments) || undefined, // Ensure label shrinks when there's a value
                }}
            />
        </Paper>
    );
};

export default ReviewSection;
