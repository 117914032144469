import React from 'react';
import { TextField, Grid, Typography, Box } from '@mui/material';
import Quote from '../../Models/Quote';

interface QuoteFormProps {
    handleQuoteFieldChange: (field: keyof Quote, value: any) => void;
    validationErrors: { phone: boolean; email: boolean; zip: boolean };
    selectedQuote: Quote | null;
}

const QuoteForm: React.FC<QuoteFormProps> = ({ handleQuoteFieldChange, validationErrors, selectedQuote }) => {
    return (
        <div>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}>Point of Contact</Typography>
            <Grid container spacing={3}>
                {/* Name */}
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Name"
                        value={selectedQuote?.contactName || ''}
                        onChange={(e) => handleQuoteFieldChange('contactName', e.target.value)}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                {/* Phone Number */}
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Phone Number"
                        value={selectedQuote?.phone || ''}
                        onChange={(e) => handleQuoteFieldChange('phone', e.target.value)}
                        error={validationErrors.phone}
                        helperText={validationErrors.phone ? "Invalid phone format" : ""}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                {/* Email Address */}
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Email Address"
                        value={selectedQuote?.email || ''}
                        onChange={(e) => handleQuoteFieldChange('email', e.target.value)}
                        error={validationErrors.email}
                        helperText={validationErrors.email ? "Invalid email format" : ""}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </Grid>
            </Grid>
            <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 2, marginBottom: 2, fontWeight: "bold", color: "#333" }}>Location</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Address"
                        value={selectedQuote?.address || ''}
                        onChange={(e) => handleQuoteFieldChange('address', e.target.value)}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField
                        label="City"
                        value={selectedQuote?.city || ''}
                        onChange={(e) => handleQuoteFieldChange('city', e.target.value)}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                <Grid item xs={3} sm={1}>
                    <TextField
                        label="State"
                        value={selectedQuote?.state || ''}
                        onChange={(e) => handleQuoteFieldChange('state', e.target.value)}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </Grid>
                <Grid item xs={3} sm={2}>
                    <TextField
                        label="Zip"
                        value={selectedQuote?.zip || ''}
                        onChange={(e) => handleQuoteFieldChange('zip', e.target.value)}
                        error={validationErrors.zip}
                        helperText={validationErrors.zip ? "Zip code must be 5 digits" : ""}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default QuoteForm;
