import React, { useState } from 'react';
import { Box, Typography, Button, Tab, Tabs, Tooltip, Dialog, Grid } from '@mui/material';
import QuoteConfiguration from '../../Models/QuoteConfiguration'; // Adjust the import path as needed
import QuoteEquipmentComponent from './QuoteEquipmentComponent';
import EquipmentType from '../../Models/EquipmentType';

interface QuoteConfigurationModalProps {
    configurations: QuoteConfiguration[];
    equipmentTypes: EquipmentType[];
    isOpen: boolean;
    onClose: () => void;
    onUpdateConfigurations: (configurations: QuoteConfiguration[]) => void;
}

const QuoteConfigurationModal: React.FC<QuoteConfigurationModalProps> = ({ configurations, equipmentTypes, isOpen, onClose, onUpdateConfigurations }) => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const handleConfigurationChange = (updatedConfig: QuoteConfiguration) => {
        const updatedConfigurations = configurations.map(config =>
            config.configIndex === updatedConfig.configIndex ? updatedConfig : config
        );
        onUpdateConfigurations(updatedConfigurations);
        setHasUnsavedChanges(false);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    const DEFAULT_CONTRACTOR_PERCENTAGE = 27;

    const handleAddPricingTable = () => {
        const newConfig: QuoteConfiguration = {
            configurationId: 0,
            quoteId: 0, // Set appropriate quoteId if necessary
            configIndex: configurations.length,
            quoteEquipment: equipmentTypes.map(e => ({
                equipmentId: 0,
                equipmentTypeId: e.equipmentTypeId,
                isChecked: e.price > 0,
                price: e.price,
                name: e.name,
                quantity: 1,
            })),
            mileageMultiplier: 0,
            perMilePrice: 0,
            installCount: 1,
            contractorPerInstallPay: 0,
            contractorPayPercentage: DEFAULT_CONTRACTOR_PERCENTAGE,
            notes: '',
        };
        // Update configurations
        const updatedConfigurations = [...configurations, newConfig];
        onUpdateConfigurations(updatedConfigurations);
        
        // Set a timeout to delay the update of selectedTabIndex
        setTimeout(() => {
            setSelectedTabIndex(updatedConfigurations.length - 1);
        }, 0);
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="lg">
            <Box padding={2}>
                <Typography variant="h6" gutterBottom>Quote Configurations</Typography>
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ marginBottom: 2 }}
                >
                    {configurations?.map((config, index) => (
                        <Tab label={`Configuration ${index + 1}`} key={index} />
                    ))}
                </Tabs>
                <Tooltip title={hasUnsavedChanges ? "Please save changes before adding a new configuration." : ""} placement="top">
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddPricingTable}
                            disabled={hasUnsavedChanges}
                            sx={{ marginBottom: 2 }}
                        >
                            Add Configuration
                        </Button>
                    </span>
                </Tooltip>
                {configurations?.map((config, index) => (
                    <div key={index} hidden={index !== selectedTabIndex}>
                        {index === selectedTabIndex && (
                            <QuoteEquipmentComponent
                                equipmentTypes={equipmentTypes}
                                currentConfig={config}
                                onConfigurationChange={handleConfigurationChange}
                                hasUnsavedChanges={setHasUnsavedChanges}
                            />
                        )}
                    </div>
                ))}
                <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
                    <Button variant="contained" color="secondary" onClick={onClose} sx={{ marginTop: 2 }}>
                        Close Configuration
                    </Button>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default QuoteConfigurationModal;
