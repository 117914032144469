import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Typography, Paper, Grid, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { legalTerms } from '../../LegalText/LegalTerms';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import ContractorService from '../../Services/ContractorService';
import ProjectService from '../../Services/ProjectService';
import QuoteService from '../../Services/QuoteService';
import jsPDF from 'jspdf';
import QuoteConfiguration from '../../Models/QuoteConfiguration';
import html2canvas from 'html2canvas';

interface ExhibitAComponentProps {
    exhibitAId: number;
    passedSignature: string;
    contractorId: number;
    projectId: number;
    onSubmitAndClose: () => void;
}

const ExhibitAComponent: React.FC<ExhibitAComponentProps> = ({ exhibitAId, passedSignature, contractorId, projectId, onSubmitAndClose }) => {
    // States for form fields - replace these with actual fields from your Exhibit A form
    const [projectPhone, setProjectPhoneNumber] = useState('');
    const [projectAddress, setProjectAddress] = useState('');
    const [projectState, setProjectState] = useState('');
    const [projectCity, setProjectCity] = useState('');
    const [projectZip, setProjectZip] = useState('');
    const [contractorName, setContractorName] = useState('');
    const [contractorDOB, setContractorDob] = useState('');
    const [contractorSSN, setContractorSSN] = useState('');
    const [contractorPhone, setContractorPhone] = useState('');
    const [contractorAddress, setContractorAddress] = useState('');
    const [contractorCity, setContractorCity] = useState('');
    const [contractorState, setContractorState] = useState('');
    const [contractorZip, setContractorZip] = useState('');
    const [projectNumber, setProjectNumber] = useState('');
    const [setPointofContact, setSetPointofContact] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [mileageRate, setMileageRate] = useState('');
    const [maxMiles, setMaxMiles] = useState('');
    const [quoteConfigurations, setQuoteConfigurations] = useState<QuoteConfiguration[]>([]);
    const [hasReadTerms, setHasReadTerms] = useState(false);
    const [dailyRate, setDailyRate] = useState(0);
    const [dayRateNotes, setDayRateNotes] = useState('');
    const [projectManager, setProjectManager] = useState('');
    const [isSiteLead, setIsSiteLead] = useState(false);
    const [siteLeadPay, setSiteLeadPay] = useState(0);
    const [siteLeadNotes, setSiteLeadNotes] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const sigCanvasRef = useRef(null);
    const [signature, setSignature] = useState(passedSignature || null);

    const httpClient = axios.create();
    const projectService = new ProjectService(httpClient);
    const contractorService = new ContractorService(httpClient);
    const quoteService = new QuoteService(httpClient);

    useEffect(() => {
        // Logic to fetch and auto-fill data based on contractorId and projectId

        //Fetch Quote Details
        quoteService.getQuoteByProjectId(projectId).then(quote => {
            setProjectPhoneNumber(quote.phone);
            setSetPointofContact(quote.contactName);
            setProjectAddress(quote.address);
            setProjectCity(quote.city);
            setProjectState(quote.state);
            setProjectZip(quote.zip);
            setMileageRate(quote.exhibitAPrice.toFixed(2));
            setMaxMiles(quote.exhibitACount);
            setQuoteConfigurations(quote.configurations || []);
            setDailyRate(quote.exhibitADayRate);
            setDayRateNotes(quote.dayRateNotes);
            setSiteLeadPay(quote.siteLeadPrice);
            setSiteLeadNotes(quote.siteLeadNotes);
        });

        // Fetch project details
        projectService.getProjectById(projectId).then(project => {
            setProjectNumber(project.projectNumber);
            setStartDate(formatDate(project.startDate));
            setEndDate(formatDate(project.endDate));

            // Check if projectManagerId is available
            if (project.projectManagerId) {
                // Fetch Project Manager in Project
                projectService.getEmployeeById(project.projectManagerId).then(employee => {
                    setProjectManager(employee.firstName + ' ' + employee.lastName);
                }).catch(error => {
                    console.error('Error fetching project manager:', error);
                    // Handle error (e.g., setProjectManager to a default value or show an error message)
                    setProjectManager('Not Assigned');
                });
            } else {
                // Handle case where projectManagerId is not set
                setProjectManager('Not Assigned');
            }

            // Fetch Site Lead ID
            // Check if siteLeadIds is not empty
            if (project.siteLeadIds) {
                // Check if siteLeadIds contains a comma
                if (project.siteLeadIds.includes(',')) {
                    // Convert siteLeadIds string to an array of numbers
                    const siteLeadIdsArray = project.siteLeadIds.split(',').map(Number);
                    // Check if the current contractor is in the site lead IDs array
                    setIsSiteLead(siteLeadIdsArray.includes(contractorId));
                } else {
                    // Directly compare as a single number
                    setIsSiteLead(parseInt(project.siteLeadIds) === contractorId);
                }
            } else {
                setIsSiteLead(false);
            }

        }).catch(error => {
            console.error('Error fetching project details:', error);
        });

        // Fetch contractor details
        contractorService.getContractorById(contractorId).then(contractor => {
            setContractorName(contractor.firstName + ' ' + contractor.lastName);
            setContractorDob(formatDate(contractor.dob));
            setContractorSSN(contractor.ssn);
            setContractorAddress(contractor.address);
            setContractorCity(contractor.city);
            setContractorState(contractor.state);
            setContractorZip(contractor.zip);
            setContractorPhone(contractor.phone);
        });
    }, [contractorId, projectId]);

    useEffect(() => {
        // Check if there's a passed signature and the canvas ref is available
        if (passedSignature && sigCanvasRef.current) {
            // Use fromDataURL to load the signature
            (sigCanvasRef.current as any).fromDataURL(passedSignature); // Added type assertion 'as any'
            setIsSubmitted(true); // Assuming you want to mark the form as submitted or disable editing
            setSignature(passedSignature);
            setHasReadTerms(true);
        }
    }, [passedSignature]);

    // Utility function to format date
    const formatDate = (dateString: string | number | Date) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Check if signature is provided
        if (!signature) {
            alert("Please provide your signature.");
            return;
        }

        // Send the data to the server
        try {
            await contractorService.saveContractorSignature(exhibitAId, signature);
            setIsSubmitted(true);
            if (onSubmitAndClose) {
                onSubmitAndClose();
            }
        } catch (error) {
            console.error('Error saving signature:', error);
            alert("Failed to save signature. Please try again.");
            // Handle error (e.g., show an error message or redirect)
        }
    };

    const clearSignature = () => {
        if (sigCanvasRef.current) {
            (sigCanvasRef.current as any).clear(); // Fix: Added 'as any' to access 'clear' method
            setSignature(null);
        }
    };

    // When the signature is drawn, update the state
    const onEndSignature = () => {
        if (sigCanvasRef.current) {
            const canvasUrl = (sigCanvasRef.current as any).getTrimmedCanvas().toDataURL('image/png');
            setSignature(canvasUrl);
        }
    };

    const createPDF = () => {
        // Ensure the element ID matches your form's container ID
        const formElement = document.getElementById('form-to-pdf');
        if (formElement) {
            html2canvas(formElement, { scale: 1 })
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF({
                        orientation: 'p',
                        unit: 'px',
                        format: [canvas.width, canvas.height]
                    });
                    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
                    pdf.save('form.pdf');
                })
                .catch((error) => {
                    console.error('Error generating PDF:', error);
                });
        } else {
            console.error('Form element not found');
        }
    };

    const renderConfigTables = () => {
        if (quoteConfigurations.length === 0 && (!dailyRate || dailyRate <= 0)) {
            return <Typography sx={{ mt: 2 }}>No configurations available.</Typography>;
        }

        return (
            <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom>Scope of Work</Typography>
                <TableContainer component={Paper} sx={{ mt: 2, boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="quote configurations table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "White" }}>
                                <TableCell sx={{ fontWeight: "bold", color: "#333", p: 2 }}>Quantity</TableCell>
                                <TableCell sx={{ fontWeight: "bold", color: "#333", p: 2 }}>Rate($)</TableCell>
                                <TableCell sx={{ fontWeight: "bold", color: "#333", p: 2 }}>Equipment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {quoteConfigurations.map((config, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" sx={{ p: 2 }}>
                                        {config.installCount}
                                    </TableCell>
                                    <TableCell align="right" sx={{ p: 2 }}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(config.contractorPerInstallPay)}
                                    </TableCell>
                                    <TableCell sx={{ p: 2 }}>   {config.quoteEquipment
                                        .filter(equip => equip.isChecked)
                                        .map(equip => `${equip.name}${equip.quantity && equip.quantity > 1 ? ` (${equip.quantity})` : ''}`)
                                        .join(', ')}</TableCell>

                                </TableRow>
                            ))}
                            {dailyRate > 0 && (
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={{ p: 2 }}>Daily Rate</TableCell>
                                    <TableCell align="right" sx={{ p: 2 }}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dailyRate)}
                                    </TableCell>
                                    <TableCell sx={{ p: 2 }}>{dayRateNotes}</TableCell>
                                </TableRow>
                            )}
                            {isSiteLead && (

                                <TableRow>
                                    <TableCell component="th" scope="row" sx={{ p: 2 }}>Site Lead Pay</TableCell>
                                    <TableCell>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(siteLeadPay)}
                                    </TableCell>
                                    <TableCell sx={{ p: 2 }}>{siteLeadNotes}</TableCell>
                                </TableRow>

                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    *Please note that the quantity provided represents the total of the project and does not reflect the amount you will personally be compensated for. You will be compensated for what you complete.
                </Typography>
                {dailyRate > 0 && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        *Day rate only applies to training pay or equipment repair at the discretion of the project manager.
                    </Typography>
                )}
            </Card>
        );
    };

    return (
        <Paper sx={{ p: 1, m: 0, maxWidth: '100%', backgroundColor: '#f5f5f5', boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' }}>
            <Typography variant="h5" align="center" gutterBottom>Exhibit A Form</Typography>
            <form id="form-to-pdf" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    {/* Project Information Section */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ p: 1, mb: 1 }}>
                            <Typography variant="h6" gutterBottom>Project Information</Typography>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Project Location ID" fullWidth value={projectNumber} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Contact Name" fullWidth value={setPointofContact} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Contact Phone Number" fullWidth value={projectPhone} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Address" fullWidth value={projectAddress} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="City" fullWidth value={projectCity} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField label="State" fullWidth value={projectState} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField label="Zip" fullWidth value={projectZip} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Project Start Date" fullWidth value={startDate} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Project End Date" fullWidth value={endDate} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Project Manager" fullWidth value={projectManager} InputProps={{ readOnly: true }} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {/* Contractor Details Section */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ p: 1, mb: 1 }}>
                            <Typography variant="h6" gutterBottom>Contractor Details</Typography>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Name" fullWidth value={contractorName} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="DOB" fullWidth value={contractorDOB} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="SSN" fullWidth value={contractorSSN} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="Phone Number" fullWidth value={contractorPhone} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Address" fullWidth value={contractorAddress} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField label="City" fullWidth value={contractorCity} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField label="State" fullWidth value={contractorState} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField label="Zip" fullWidth value={contractorZip} InputProps={{ readOnly: true }} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    {/* Scope of Work Section */}
                    <Grid item xs={12}>

                        {renderConfigTables()}
                    </Grid>
                    {/* Mileage and Lodging Section */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ p: 1, mb: 1 }}>
                            <Typography variant="h6" gutterBottom>Mileage and Lodging</Typography>
                            <Typography variant="body1">

                                <strong>Mileage:</strong> {mileageRate} per mile up to {maxMiles} miles. Mileage is paid after 30 miles from
                                the Technician's Home to the 1st site and after 30 miles from the last site to the technician’s home. Mileage is not
                                paid in between job sites unless deemed excessive and approved by IMS Project Manager.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                <strong>Lodging:</strong> Lodging will be paid on a per job basis at the sole discretion of the Company. No other persons or pets shall
                                be allowed to stay in company-paid lodging at any time.
                            </Typography>
                        </Card>
                    </Grid>
                    {/* Legal Verbiage Section */}
                    <Grid item xs={12}>
                        <Box sx={{ p: 2, mb: 2, backgroundColor: 'white', borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom>Legal Terms and Conditions</Typography>
                            <TextField
                                fullWidth
                                multiline
                                value={legalTerms}
                                InputProps={{ readOnly: true }}
                                variant="outlined"
                                sx={{
                                    maxHeight: 300,
                                    overflowY: 'auto',
                                    '& .MuiInputBase-inputMultiline': {
                                        height: 'auto',
                                        padding: 1
                                    }
                                }}
                            />
                            <br />
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasReadTerms}
                                        onChange={(e) => setHasReadTerms(e.target.checked)}
                                    />
                                }
                                label="I have read and understood the Legal Terms and Conditions"
                            />
                        </Box>
                    </Grid>
                    {/* User Acknowledgment for Legal Terms */}
                    <Grid item xs={12}>

                    </Grid>

                    {/* Signature Capture Section */}
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Signature</Typography>
                            <div style={{ position: 'relative' }}>
                                <SignatureCanvas
                                    penColor='black'
                                    canvasProps={{
                                        className: 'sigCanvas',
                                        style: { width: '100%', height: 200 }
                                    }}
                                    ref={sigCanvasRef}
                                    onEnd={onEndSignature}
                                />
                                {isSubmitted && (
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 2, // Ensure this is above the SignatureCanvas layer
                                        cursor: 'not-allowed' // Optionally change the cursor to indicate the area is disabled
                                    }}></div>
                                )}
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
                                <Button onClick={clearSignature} variant="outlined" color="secondary" disabled={isSubmitted}>Clear</Button>
                            </Box>
                        </Card>
                    </Grid>
                    {/* Submit Button */}
                    <Grid item xs={12}>
                        <Tooltip
                            title={!hasReadTerms || !signature ? "Please acknowledge the legal terms and provide your signature to enable this button" : ""}
                            placement="top"
                        >
                            <span> {/* Span is necessary as Tooltip doesn't work with disabled buttons */}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!hasReadTerms || !signature || isSubmitted} // Updated condition to include signature check
                                >
                                    Submit Form
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
            <Button onClick={createPDF} variant="contained" color="primary" sx={{ mt: 2 }}>Download PDF</Button>
        </Paper>
    );
};


export default ExhibitAComponent;