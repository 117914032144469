import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Profile from './Profile';
import SignIn from './SignIn';
import Contractor from './Contractor/Contractor';
import AdminDashboard from './Admin/Dashboard';
import VehicleUpload from './Vehicle/VehicleUpload';
import ProjectDashboard from './Projects/ProjectDashboard';
import QuoteDashboard from './Quotes/QuoteDashboard';
import ProtectedRoute from './ProtectedRoute';
import Unauthorized from './UnAuthorized';
import AdminApprovalDashboard from './AdminApproval/AdminApprovalDashboard';

function Content() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/admin" element={
          <ProtectedRoute
            roles={['Admin']}
            element={<AdminDashboard />}
          />
        } /> */}
        <Route path="/contractor" element={
          <ProtectedRoute
            roles={['contractor']}
            element={<Contractor />}
          />
        } />
        {/* <Route path="/vehicle-upload" element={
          <ProtectedRoute
            roles={['Admin', 'contractor']}
            element={<VehicleUpload />}
          />
        } /> */}
        <Route path="/ProjectDashboard" element={
          <ProtectedRoute
            roles={['Admin']}
            element={<ProjectDashboard />}
          />
        } />
        <Route path="/QuoteDashboard" element={
          <ProtectedRoute
            roles={['Admin']}
            element={<QuoteDashboard />}
          />
        } />
        <Route path="/AdminApprovalDashboard" element={
          <ProtectedRoute
            roles={['Admin']}
            element={<AdminApprovalDashboard />}
          />
        } />
        <Route path="/unauthorized" element={<Unauthorized />} />
        {/* Redirect all unmatched paths to the root */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </main>
  );
}

export default Content;