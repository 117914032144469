import React, { useState, useEffect, useRef } from 'react';
import { SetStateAction } from 'react';
import {
  Button, Select, MenuItem,
  Typography, FormControl, InputLabel, styled, Box,
  Grid, Accordion, AccordionSummary, AccordionActions,
  Snackbar, AlertColor, Alert, TextField, Paper, Card, TablePagination
} from '@mui/material';
import ProjectService from '../../Services/ProjectService';
import { generateUniqueProjectNumber } from '../../Utilities/CreateProjectNumber';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import Organization from '../../Models/Organization';
import Quote from '../../Models/Quote';
import QuoteService from '../../Services/QuoteService';
import Note from '../../Models/Note';
import QuoteConfiguration from '../../Models/QuoteConfiguration';
import EquipmentType from '../../Models/EquipmentType';
import EquipmentService from '../../Services/EquipmentService';
import QuoteForm from './QuoteForm';
import NotesSection from '../NotesComponent/NotesSection';
import FileUploadComponent from '../File/FileUploadComponent';
import EnhancedFile from '../../Models/EnhancedFile';
import QuoteConfigurationModal from './QuoteConfigurationModal';

// Define styled components
const Header = styled(Typography)({
  marginBottom: '20px',
  color: 'black',
  textAlign: 'center',
});

const StyledGenerateButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4CAF50', // Green color
  color: 'white',
  '&:hover': {
    backgroundColor: '#388E3C', // Darker shade for hover state
  },
}));

const CustomAccordion = styled(Accordion)({
  marginTop: '16px',
  backgroundColor: 'linear-gradient(45deg, #e3e9ed 30%, #f5f5f5 90%)',
  border: '1px solid #e0e0e0',
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
});

const AccordionContentBox = styled(Box)({
  marginTop: '16px',
  padding: '16px 24px',
  border: '1px solid #e0e0e0',
  borderRadius: '5px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
});

const QuoteDashboard: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);
  const [validationErrors, setValidationErrors] = useState({ phone: false, email: false, zip: false });
  const [currentNote, setCurrentNote] = useState<string>("");
  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentType[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [selectedFiles, setSelectedFiles] = useState<EnhancedFile[]>([]);

  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [currentConfigurations, setCurrentConfigurations] = useState<QuoteConfiguration[]>([]);
  const [mileageCost, setMileageCost] = useState(0);
  const [mileageMultiplier, setMileageMultiplier] = useState(0);
  const [exhibitAMileageCost, setExhibitAMileageCost] = useState(0);
  const [exhibitAMileageMultiplier, setExhibitAMileageMultiplier] = useState(0);
  const [dayRateAmount, setDayRateAmount] = useState(0);
  const [exhibitADayRateAmount, setExhibitADayRateAmount] = useState(0);
  const [dailyRateNotes, setDailyRateNotes] = useState('');
  const [siteLeadAmount, setSiteLeadAmount] = useState(0);
  const [siteLeadNotes, setSiteLeadNotes] = useState('');

  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // State for filtering (example: by quote number)
  const [filter, setFilter] = useState('');

  // Handle change page
  const handleChangePage = (event: any, newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  // Handle change rows per page
  const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const httpClient = axios.create();
  const projectService = new ProjectService(httpClient); // Instantiate the ProjectService
  const quoteService = new QuoteService(httpClient);
  const equipmentService = new EquipmentService(httpClient);
  // Regular Expressions for Validation
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  useEffect(() => {
    const fetchOrganizations = async () => {
      const fetchedOrgs = await projectService.getOrganizations();
      setOrganizations(fetchedOrgs);
    };

    fetchOrganizations();

  }, []);  // This runs only once when the component mounts

  useEffect(() => {
    if (!selectedOrg) return;

    const fetchQuotes = async () => {
      const fetchedQuotes = await quoteService.getQuotesByOrganizationId(selectedOrg.organizationId);
      const fetchEquipmentTypes = await equipmentService.getEquipmentByOrganizationId(selectedOrg?.organizationId as number);
      console.log("fetchedQUotes", fetchedQuotes);
      setQuotes(fetchedQuotes || []);
      setEquipmentTypes(fetchEquipmentTypes || []);
    };
    fetchQuotes();
  }, [selectedOrg]);  // This runs whenever selectedOrg changes

  const prevQuoteIdRef = useRef<number | null>(null);

  useEffect(() => {
    const fetchFilesForSelectedQuote = async () => {
      if (selectedQuote && selectedQuote.quoteId && selectedQuote.quoteId !== prevQuoteIdRef.current) {
        try {
          const response = await quoteService.HandleLoadFiles(selectedQuote.quoteId);
          var files = new Array<EnhancedFile>();
          response.forEach((file: EnhancedFile) => {
            files.push(file);
          });

          if (files.length > 0) {
            setSelectedFiles(files);
          } else {
            setSelectedFiles([]);
          }
        } catch (error) {
          setSelectedFiles([]); // Optionally reset to an empty array on error
        }
      }
      prevQuoteIdRef.current = selectedQuote?.quoteId ?? null;
      setMileageCost(selectedQuote?.mileagePrice || 0);
      setMileageMultiplier(selectedQuote?.mileageCount || 0);
      setExhibitAMileageCost(selectedQuote?.exhibitAPrice || 0);
      setExhibitAMileageMultiplier(selectedQuote?.exhibitACount || 0);
      setDayRateAmount(selectedQuote?.dayRatePrice || 0);
      setExhibitADayRateAmount(selectedQuote?.exhibitADayRate || 0);
      setDailyRateNotes(selectedQuote?.dayRateNotes || '');
      setSiteLeadAmount(selectedQuote?.siteLeadPrice || 0);
      setSiteLeadNotes(selectedQuote?.siteLeadNotes || '');
    };

    fetchFilesForSelectedQuote();
  }, [selectedQuote?.quoteId]); // Dependency on selectedQuote's ID only


  const handleCreateQuote = () => {
    // Create a new quote object (you might add more properties as per your Quote interface)
    const newQuote: Quote = {
      quoteId: 0, // Generate or fetch a new ID
      projectId: 0,
      name: '',
      quoteNumber: `${generateUniqueProjectNumber()}`, // Generate a unique quoteNumber
      organizationId: selectedOrg?.organizationId || 0,
      contactName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      notes: [],
      status: 'Draft',
      createdDate: new Date(),
      UpdatedAt: new Date(),
      mileagePrice: 0,
      mileageCount: 0,
      exhibitAPrice: 0,
      exhibitACount: 0,
      dayRatePrice: 0,
      exhibitADayRate: 0,
      dayRateNotes: '',
      siteLeadPrice: 0,
      siteLeadNotes: '',
      configurations: [],

      // ... other properties ...
    };
    // Add the new quote to the state and potentially save to the DB
    setSelectedFiles([]); // Clear the selected files
    setQuotes((prevQuotes) => [...prevQuotes, newQuote]);
    setSelectedQuote(newQuote); // Set the new quote as the selected quote
  };

  const handleSaveQuote = async () => {
    try {
      const newQuote: Quote = {
        // Populate the Quote fields. For instance:
        quoteId: selectedQuote?.quoteId as number,
        organizationId: selectedOrg?.organizationId as number,
        projectId: selectedQuote?.projectId as number,
        name: selectedQuote?.name as string,
        quoteNumber: selectedQuote?.quoteNumber as string,
        contactName: selectedQuote?.contactName as string,
        email: selectedQuote?.email as string,
        phone: selectedQuote?.phone as string,
        address: selectedQuote?.address as string,
        city: selectedQuote?.city as string,
        state: selectedQuote?.state as string,
        zip: selectedQuote?.zip as string,
        notes: selectedQuote?.notes || [],
        status: selectedQuote?.status as string,
        createdDate: new Date(),
        UpdatedAt: new Date(),
        mileagePrice: Number(mileageCost.toFixed(2)),
        mileageCount: mileageMultiplier,
        exhibitAPrice: Number(exhibitAMileageCost.toFixed(2)),
        exhibitACount: exhibitAMileageMultiplier,
        dayRatePrice: Number(dayRateAmount.toFixed(2)),
        exhibitADayRate: Number(exhibitADayRateAmount.toFixed(2)),
        dayRateNotes: dailyRateNotes,
        siteLeadPrice: Number(siteLeadAmount.toFixed(2)),
        siteLeadNotes: siteLeadNotes,
        configurations: selectedQuote?.configurations ?? []
      };

      console.log('newQuote', newQuote);
      let savedQuote: React.SetStateAction<Quote | null>;
      let isNewQuote = selectedQuote?.quoteId === 0;

      if (isNewQuote) {
        savedQuote = await quoteService.CreateQuote(newQuote);
        setQuotes(prevQuotes => [...prevQuotes.filter(q => q.quoteId !== 0), savedQuote as Quote]);
      } else {
        savedQuote = await quoteService.UpdateQuote(selectedQuote?.quoteId as number, newQuote);
        setQuotes(prevQuotes => prevQuotes.map(q => q.quoteId === (savedQuote as Quote)?.quoteId ? (savedQuote as Quote) : q));
      }

      // Upload files for the quote
      if (selectedFiles.length > 0) {
        const formData = new FormData();
        selectedFiles.forEach(file => {
          formData.append('files', file.file);
        });
        await quoteService.UploadFiles(selectedQuote?.quoteId as number, formData)
      }

      setSelectedQuote(savedQuote); // Update the selected quote with the latest data
      setSnackbarMessage('Quote saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to save quote:', error);
      setSnackbarMessage('Error saving the quote. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleQuoteFieldChange = (field: keyof Quote, value: any) => {
    if (field === 'phone') {
      // Strip non-numeric characters
      const cleaned = value.replace(/\D/g, '');

      // Break the numbers into groups and add hyphens
      const formatted = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      value = !formatted[2] ? formatted[1] : `${formatted[1]}-${formatted[2]}${formatted[3] ? '-' + formatted[3] : ''}`;

      setValidationErrors(prev => ({ ...prev, phone: !phoneRegex.test(value) }));
    }

    if (field === 'email') {
      setValidationErrors(prev => ({ ...prev, email: !emailRegex.test(value) }));
    }

    if (field === 'zip') {
      const isValidZip = /^\d{5}$/.test(value);
      setValidationErrors(prev => ({ ...prev, zip: !isValidZip }));
    }

    setSelectedQuote(prev => {
      if (prev) {
        if (field === 'city') {
          // When the city is edited, update the quote name too
          return { ...prev, city: value, name: value };
        }
        return { ...prev, [field]: value };
      }
      return null;
    });


  };

  const handleAddNote = () => {
    if (currentNote.trim() !== "") {
      const newNote: Note = { timeStamp: new Date(), content: currentNote };
      setSelectedQuote(prev => {
        if (prev) {
          const updatedQuote: Quote = { ...prev, notes: [...(prev.notes || []), newNote] };
          return updatedQuote;
        }
        return null;
      });
      setCurrentNote("");  // Clear the current note
    }
  };

  const handleCreateProjectFromQuote = async () => {
    if (!selectedQuote) {
      setSnackbarMessage('Please select a quote first.');
      setSnackbarOpen(true);
      return;
    }

    try {
      console.log('selectedQuote at create', selectedQuote);
      const returnedQuote = await quoteService.CreateProjectFromQuote(selectedQuote);

      setSelectedQuote(returnedQuote);
      setSnackbarMessage('Project created successfully from the selected quote!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      // Optionally, refresh the list of projects or navigate to the new project's page
    } catch (error) {
      console.error('Failed to create project from quote:', error);
      setSnackbarMessage('Error creating project from the selected quote. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : [];
    console.log("New files:", newFiles);

    // Transform each File object into an EnhancedFile object
    const enhancedNewFiles: EnhancedFile[] = newFiles.map(file => ({
      file,
      fileId: 0, // Initially set to 0
      fileName: file.name,
      size: file.size, // Add size property
      type: file.type, // Add type property
      uploadDate: file.lastModified.toString(), // Convert to string
      projectId: selectedQuote?.quoteId || 0, // Initially set to 0
      s3Key: '', // Initially set to empty string
      presignedUrl: '' // Initially set to null or empty string
    }));

    console.log("Enhanced new files:", enhancedNewFiles);

    // Merge new files with the existing selected files
    setSelectedFiles(prevFiles => [...prevFiles, ...enhancedNewFiles]);
  };

  const handleOpenConfigurationModal = (quote: Quote) => {
    if (isConfigModalOpen) {
      console.log("Modal is already open.");
      return;
    }
    setCurrentConfigurations(quote.configurations || []);
    setIsConfigModalOpen(true);
  };

  const handleCloseConfigurationModal = () => {
    setIsConfigModalOpen(false);
  };

  const handleConfigurationsUpdate = (updatedConfigs: any) => {
    setSelectedQuote(prevQuote => {
      if (prevQuote) {
        return { ...prevQuote, configurations: updatedConfigs };
      }
      return null;
    });
  };

  useEffect(() => {
    // This function recalculates totals whenever relevant fields change
    const calculateTotals = () => {
      // Assuming you have a function to calculate total job pay
      const totalJobPay = calculateTotalJobPay(selectedQuote?.configurations || []);
      // Assuming you have a function to calculate total contractor pay
      const totalContractorPay = calculateTotalContractorPay(selectedQuote?.configurations || []);
    };
  
    calculateTotals();
  }, [siteLeadAmount, mileageCost, mileageMultiplier, dayRateAmount, selectedQuote?.configurations]);

  const calculateTotalJobPay = (configurations: any[]) => {
    const equipmentTotal = configurations.reduce((total: number, config: { quoteEquipment: any[]; installCount: number; }) => {
      const configTotal = config.quoteEquipment
        .filter((equipment: { isChecked: boolean; price: number; quantity?: number; }) => equipment.isChecked && equipment.price)
        .reduce((sum: number, equipment: { price: number; quantity?: number; }) => {
          const quantity = equipment.quantity || 1; // Default quantity to 1 if it's not specified
          return sum + (equipment.price * quantity);
        }, 0) * config.installCount;
      return total + configTotal; // Sum all configuration totals
    }, 0);

    // Calculate the mileage cost
    const mileageTotal = mileageCost * mileageMultiplier;

    // Calculate the day rate total
    const dayRateTotal = dayRateAmount; // Assuming dayRateAmount is the total day rate for the quote

    // Sum up the equipment total, mileage total, and day rate total
    const totalJobPay = equipmentTotal + mileageTotal + dayRateTotal;

    return totalJobPay.toLocaleString(); 
  };

  const calculateTotalContractorPay = (configurations: any[]) => {
    const totalPay = configurations.reduce((total, config) => {
      // Calculate pay for each equipment in the configuration
      const configPay = config.quoteEquipment.reduce((sum: number, equipment: { isChecked: any; price: number; quantity: number; }) => {
        if (equipment.isChecked) {
          // Account for the equipment quantity in the calculation
          const equipmentContribution = ((equipment.price * equipment.quantity) * config.installCount) * (config.contractorPayPercentage / 100);
          return sum + equipmentContribution;
        }
        return sum;
      }, 0);
  
      // Add site lead pay (assuming it doesn't depend on equipment quantity but only install count)
      const siteLeadPay = siteLeadAmount * config.installCount;
  
      // Sum and log intermediate total for debugging
      const intermediateTotal = total + configPay + siteLeadPay;
      
      return intermediateTotal;
    }, 0);
  
    const roundedTotalPay = Math.round(totalPay); // Final rounding to nearest dollar
    return roundedTotalPay.toLocaleString();
  };
  

  return (
    <>
      <Header text-align='left' fontWeight='bold' variant="h4" sx={{ marginBottom: 3 }}>
        Quote Dashboard
      </Header>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="org-select-label">Select an Organization</InputLabel>
              <Select
                labelId="org-select-label"
                id="org-select"
                value={selectedOrg?.organizationId || ''}
                onChange={(e) => {
                  const orgId = Number(e.target.value);
                  setSelectedOrg(organizations.find((org) => org.organizationId === orgId) || null);
                }}
                label="Select an Organization"
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org.organizationId} value={org.organizationId}>{org.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateQuote}
              disabled={!selectedOrg} // Ensure the button is enabled only if an organization is selected
              sx={{ alignSelf: 'start' }} // Align the button to the start of the container
            >
              Create Quote
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <TextField
              fullWidth
              label="Filter by Location Name"
              value={filter}
              onChange={e => setFilter(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <Card variant="outlined" sx={{ height: 900, p: 2, maxHeight: 1000, overflow: 'auto' }}>
              {quotes.filter(quote => quote.city.toLowerCase().includes(filter.toLowerCase()))
                .sort((a, b) => new Date(b.UpdatedAt).getTime() - new Date(a.UpdatedAt).getTime())
                .map(quote => (
                  <CustomAccordion
                    key={quote.quoteId}
                    expanded={selectedQuote?.quoteId === quote.quoteId}
                    onChange={() => {
                      if (selectedQuote?.quoteId === quote.quoteId) {
                        setSelectedQuote(null); // Unselect the quote to collapse its accordion
                      } else {
                        setSelectedQuote(quote); // Set the clicked quote as the selected one
                      }
                    }}
                    sx={{ marginTop: 3, backgroundColor: 'linear-gradient(45deg, #e3e9ed 30%, #f5f5f5 90%)', border: '1px solid #e0e0e0', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${selectedQuote?.quoteId}-content`}
                      id={`panel-${selectedQuote?.quoteId}-header`}
                      sx={{ backgroundColor: '#f1f5f8', borderBottom: '1px solid #e0e0e0', fontWeight: 'bold' }}
                    >
                      <Box display="flex" alignItems="center" gap={0}>
                        <Typography variant="subtitle1" component="span" sx={{ color: "black", fontWeight: "bold" }}>
                          {`${quote.quoteNumber}-${(selectedQuote?.quoteId === quote.quoteId) ? selectedQuote.city : quote.city}`}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionContentBox>
                      <QuoteForm
                        handleQuoteFieldChange={handleQuoteFieldChange}
                        validationErrors={validationErrors}
                        selectedQuote={selectedQuote}
                      />
                    </AccordionContentBox>
                    <AccordionContentBox>
                      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}>
                        Pricing Totals
                      </Typography>
                      <Grid container spacing={4} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Mileage Cost ($)"
                            type="number"
                            value={mileageCost}
                            onChange={(e) => setMileageCost(parseFloat(e.target.value))}
                          
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0, step: '0.01' } }}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Mileage Multiplier"
                            type="number"
                            value={mileageMultiplier}
                            onChange={(e) => setMileageMultiplier(Number(e.target.value))}
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Exhibit A Cost ($)"
                            type="number"
                            value={exhibitAMileageCost.toFixed(2)}
                            onChange={(e) => setExhibitAMileageCost(Number(e.target.value))}
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0, step: '0.01' } }}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Exhibit A Multiplier"
                            type="number"
                            value={exhibitAMileageMultiplier}
                            onChange={(e) => setExhibitAMileageMultiplier(parseFloat(e.target.value))}
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Daily Rate ($)"
                            type="number"
                            value={dayRateAmount}
                            onChange={(e) => setDayRateAmount(parseFloat(e.target.value))}
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0, step: '1.00' } }}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Exhibit A Daily Rate ($)"
                            type="number"
                            value={exhibitADayRateAmount}
                            onChange={(e) => setExhibitADayRateAmount(parseFloat(e.target.value))}
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0, step: '1.00' } }}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Daily Rate Notes"
                            type="string"
                            value={dailyRateNotes}
                            onChange={(e) => setDailyRateNotes(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Site Lead Pay ($)"
                            type="number"
                            value={siteLeadAmount}
                            onChange={(e) => setSiteLeadAmount(parseFloat(e.target.value))}
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0, step: '1.00' } }}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Site Lead Notes"
                            type="string"
                            value={siteLeadNotes}
                            onChange={(e) => setSiteLeadNotes(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                      </Grid>
                      <Box sx={{ p: 2, backgroundColor: '#f1f5f8', borderRadius: '5px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                          Total Job Pay: ${calculateTotalJobPay(selectedQuote?.configurations || [])}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                          Total Contractor Pay: ${calculateTotalContractorPay(selectedQuote?.configurations || [])}
                        </Typography>
                      </Box>
                    </AccordionContentBox>

                    <AccordionContentBox>
                      <NotesSection
                        currentNote={currentNote}
                        setCurrentNote={setCurrentNote} // Pass the function as a prop
                        handleAddNote={handleAddNote}
                        selectedQuote={selectedQuote}
                      />
                    </AccordionContentBox>
                    <AccordionContentBox>
                      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>File Upload</Typography>
                      <FileUploadComponent
                        onFileChange={handleFileChange}
                        onFilePreview={() => { }}
                        files={selectedFiles}
                      />
                    </AccordionContentBox>
                    <QuoteConfigurationModal
                      configurations={selectedQuote?.configurations || []}
                      equipmentTypes={equipmentTypes}
                      isOpen={isConfigModalOpen}
                      onClose={handleCloseConfigurationModal}
                      onUpdateConfigurations={handleConfigurationsUpdate}
                    />
                    <AccordionActions>
                      <Button
                        variant="outlined"
                        onClick={() => handleOpenConfigurationModal(quote)}
                      >
                        View Configurations
                      </Button>
                      <StyledGenerateButton
                        onClick={handleCreateProjectFromQuote}
                      >
                        Project from Quote
                      </StyledGenerateButton>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveQuote}
                      >
                        Save Quote
                      </Button>
                    </AccordionActions>
                  </CustomAccordion>
                ))}
            </Card>
            <TablePagination
              component="div"
              count={quotes.filter(quote => quote.city.toLowerCase().includes(filter.toLowerCase())).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default QuoteDashboard;
