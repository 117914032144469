import React from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, TextField, styled } from '@mui/material';
import EquipmentType from '../../Models/EquipmentType';

// Styled Components
const StyledTableCell = styled(TableCell)({
    fontSize: '14px',
    padding: '8px 16px',
});

const StyledTextField = styled(TextField)({
    '& input': {
        fontSize: '14px',
        padding: '6px 8px',
    },
});

interface EquipmentTableProps {
    equipmentTypes: EquipmentType[];
    currentPrices: Record<string, number>;
    onPriceChange: (equipmentTypeId: number, price: number) => void;
    currentQuantities: Record<string, number>;
    onQuantityChange: (equipmentTypeId: number, quantity: number) => void;
}

const EquipmentTable: React.FC<EquipmentTableProps> = ({ equipmentTypes, currentPrices, onPriceChange, currentQuantities, onQuantityChange }) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Equipment Type</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Qty</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {equipmentTypes.map((type: EquipmentType) => (
                    <TableRow key={type.equipmentTypeId}>
                        <StyledTableCell>{type.name}</StyledTableCell>
                        <StyledTableCell>
                            <StyledTextField
                                type="number"
                                value={currentPrices[type.equipmentTypeId] || 0}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPriceChange(type.equipmentTypeId, Number(e.target.value))}
                                fullWidth
                                InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <StyledTextField
                                type="number"
                                value={currentQuantities[type.equipmentTypeId] || 1} // Use the quantity from currentQuantities
                                onChange={(e) => onQuantityChange(type.equipmentTypeId, Number(e.target.value))}
                                fullWidth
                                InputProps={{ inputProps: { min: 1 } }} // Ensure quantity can't be less than 1
                            />
                        </StyledTableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default EquipmentTable;
