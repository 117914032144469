import React from 'react';
import { Typography, Grid, TextField } from '@mui/material';

interface PricingDetailsProps {
    installCount: number;
    total: number;
    contractorPayPercentage: number;
    notes: string;
    onInstallCountChange: (count: number) => void;
    onContractorPayPercentageChange: (percentage: number) => void;
    onNotesChange: (notes: string) => void;
}

const PricingDetails: React.FC<PricingDetailsProps> = ({
    installCount,
    total,
    contractorPayPercentage,
    notes,
    onInstallCountChange,
    onContractorPayPercentageChange,
    onNotesChange
}) => {
    // Calculate contractor pay per install
    const calculateContractorPayPerInstall = () => {
        const payPerInstall = (total / installCount) * contractorPayPercentage / 100;
        return Math.round(payPerInstall); // Rounds to the nearest whole number
    };

    const handleContractorPayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPay = Number(event.target.value);
        const newPercentage = (newPay * 100) / (total / installCount);
        onContractorPayPercentageChange(newPercentage);
    };

    const calculateContractorTotalPay = () => {
        const payPerInstall = (total / installCount) * (contractorPayPercentage / 100);
        const totalPay = installCount * payPerInstall;
        const roundedTotalPay = Math.round(totalPay);
        return roundedTotalPay.toLocaleString(); // Converts the number to a string with commas
    };


    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="subtitle1" color="primary">Pricing Details:</Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6">Total Install Price: ${total.toLocaleString()}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6">Contractor Total Pay: ${calculateContractorTotalPay()}</Typography>
            </Grid>
            <Grid item>
                <TextField
                    label="Contractor Pay Per Install ($)"
                    type="number"
                    value={calculateContractorPayPerInstall().toFixed(0)}
                    onChange={handleContractorPayChange}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, step: 1.00 } }}
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Contractor Pay (%)"
                    type="number"
                    value={contractorPayPercentage.toFixed(2)}
                    onChange={(e) => onContractorPayPercentageChange(parseFloat(e.target.value))}
                    fullWidth
                    InputProps={{ inputProps: { min: 0, max: 100, step: 0.01 } }}
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Vehicle Count"
                    type="number"
                    value={installCount}
                    onChange={(e) => onInstallCountChange(parseInt(e.target.value, 10) || 1)}
                    fullWidth
                    InputProps={{ inputProps: { min: 1 } }}
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Vehicle Notes"
                    multiline
                    rows={4}
                    value={notes}
                    onChange={(e) => onNotesChange(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        </Grid>
    );
};

export default PricingDetails;