import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, AlertColor, Alert, Snackbar } from '@mui/material';
import Vehicle from '../../Models/Vehicle';
import Project from '../../Models/Project';
import InspectionForm from '../Contractor/InspectionForm';
import axios from 'axios';
import EquipmentService from '../../Services/EquipmentService';
import InspectionFormsService from '../../Services/InspectionFormsService';
import VehicleService from '../../Services/VehicleService';
import ContractorService from '../../Services/ContractorService';
import InspectionFormObject from '../../Models/InspectionForm'; // Ensure this is correctly defined

type PMApprovalModalProps = {
    vehicle: Vehicle;
    project: Project;
    onClose: () => void;
    onRefresh: () => void; // Adapt as needed
};

const PMApprovalModal: React.FC<PMApprovalModalProps> = ({ vehicle, project, onClose, onRefresh }) => {
    const modalStyle = {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const inspectionMode = 'reviewPM'; // Set the inspection mode

    const [openInspectionForm, setOpenInspectionForm] = useState(false);
    const [effectiveContractorDetails, setEffectiveContractorDetails] = useState<{ contractorId: number; firstName: string; lastName: string }>({ contractorId: 0, firstName: '', lastName: '' });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const httpClient = axios.create();
    const vehicleService = new VehicleService(httpClient);
    const inspectionFormsService = new InspectionFormsService(httpClient);
    const contractorService = new ContractorService(httpClient);

    useEffect(() => {
        const fetchData = async () => {
            console.log("Vehicle selected for inspection:", vehicle);
            const reviewedContractorDetails = await contractorService.getContractorById(vehicle.contractorId);
            console.log(reviewedContractorDetails);
            setEffectiveContractorDetails(reviewedContractorDetails);
            setOpenInspectionForm(true); // Open the dialog
        };

        fetchData();
    }, [vehicle]); // Ensure it runs only when the vehicle prop changes

    const updateVehicleStatus = async (vehicleId: number, newStatus: string) => {
        try {
            // Simulate a backend call to update the vehicle's status
            const updatedVehicle = await vehicleService.updateVehicleStatus(vehicle.vehicleId, vehicle.contractorId as number, newStatus);
            console.log(`Vehicle ${vehicleId} updated to ${newStatus}`);
            return updatedVehicle;
        } catch (error) {
            console.error("Error updating vehicle status:", error);
        }
    };

    const handleReviewInspectionForm = async (formData: InspectionFormObject) => {
        // Handle reviewing the inspection form
        console.log("Reviewing inspection form:", formData);

        // Ensure formId is not null before proceeding
        if (formData.formId === null) {
            console.error("Form ID is null, cannot proceed with review.");
            setSnackbarSeverity('error');
            setSnackbarMessage('Form ID is null, cannot proceed with review.');
            setSnackbarOpen(true);
            return;
        }

        try {

                await updateVehicleStatus(vehicle.vehicleId as number, formData.projectManagerApprovalStatus);

            console.log(formData);
            // Update the inspection form status
            await inspectionFormsService.updateInspectionFormStatus(formData.formId, formData.projectManagerComments, formData.projectManagerApprovalStatus, formData.projectManagerSignature);

            setSnackbarSeverity('success');
            setSnackbarMessage('Inspection form reviewed successfully');
            setSnackbarOpen(true);

            onRefresh();
            setTimeout(() => onClose(), 500);  // Delay the close to ensure users see the transition
        } catch (error) {
            console.error("Error saving form data:", error);
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to review inspection form');
            setSnackbarOpen(true);  // Set Snackbar to open here but it will trigger after onClose
        }
    };

    const handleSaveFormData = async (formData: InspectionFormObject) => {
        console.log("Saving form data:", formData);
        // Implement your save logic here if necessary
    };

    return (
        <>
            <InspectionForm
                mode="reviewPM"
                open={openInspectionForm}
                onClose={() => setOpenInspectionForm(false)}
                initialVehicle={vehicle}
                onSave={handleSaveFormData}
                contractorDetails={{
                    ...effectiveContractorDetails,
                    dob: new Date(),
                    email: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    phone: '',
                    status: '',
                    // Add the missing properties here
                }}
                onReview={handleReviewInspectionForm}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity as AlertColor} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );

};

export default PMApprovalModal;
