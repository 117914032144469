import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  SelectChangeEvent,
  Paper,
  Alert,
  Snackbar,
  AlertColor,
  CircularProgress,
} from '@mui/material';
import ProjectService from '../../Services/ProjectService';
import Project from '../../Models/Project';
import axios from 'axios';
import VehicleComponent from '../Vehicle/VehicleComponent'
import Vehicle from '../../Models/Vehicle';
import VehicleService from '../../Services/VehicleService';
import Organization from '../../Models/Organization';
import EnhancedFile from '../../Models/EnhancedFile';
import ProjectDialog from './ProjectDialog';
import ProjectTable from './ProjectTable';
import ContractorService from '../../Services/ContractorService';
import Contractor from '../../Models/Contractor';
import Employee from '../../Models/Employee';

const ProjectDashboard: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<number | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(true);
  const [projectVehicles, setProjectVehicles] = useState<Vehicle[]>([]);
  const [isVehicleModalOpen, setVehicleModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<EnhancedFile[]>([]);
  const [previewFile, setPreviewFile] = useState<EnhancedFile | null>(null);
  const [contractors, setContractors] = useState<Contractor[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isModified, setIsModified] = useState(false);

  const httpClient = axios.create();
  const projectService = new ProjectService(httpClient);
  const vehicleService = new VehicleService(httpClient);


  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    const fetchedOrgs: Organization[] = await projectService.getOrganizations();
    setOrganizations(fetchedOrgs);
  };

  useEffect(() => {
    setLoading(true);
    if (selectedOrg) {
      fetchProjects(selectedOrg).finally(() => setLoading(false));
    }
  }, [selectedOrg]);

  const fetchProjects = async (orgId: number) => {
    try {
      const projects: Project[] = await projectService.getProjectsByOrganizationId(orgId);

      if (projects.length > 0) {
        const updatedProjects = await Promise.all(projects.map(async project => {
          const vehicles: Vehicle[] = await vehicleService.getVehiclesByProjectId(project.projectId);
          return { ...project, vehicles };
        }));
        setProjects(updatedProjects);
      } else {
        setProjects([]);
      }
    } catch (error) {
      console.error("Error fetching projects and vehicles:", error);
    }
    console.log("Projects:", projects);
  };

  const handleOrgChange = (event: SelectChangeEvent<any>) => {
    const selectedValue = Number(event.target.value);
    console.log("Organization changed to:", selectedValue);
    setSelectedOrg(selectedValue);
  };

  const handleEditProjectClick = async (project: Project) => {
    project.startDate = new Date(project.startDate);
    project.endDate = new Date(project.endDate);
    project.createdDate = new Date(project.createdDate);
    project.lastUpdated = new Date(project.lastUpdated);

    setSelectedProject(project);

    try {
      const response = await projectService.HandleLoadFiles(project.projectId);
      var files = new Array<EnhancedFile>();
      response.forEach((file: EnhancedFile) => {
        files.push(file);
      });
      console.log("files:", files);
      console.log('Files found for this project:', response.data);

      if (files.length > 0) {
        // Set the received file metadata to state
        setSelectedFiles(files);
      } else {
        console.log('No files found for this project.');
        setSelectedFiles([]);
      }
    } catch (error) {
      console.error('Error fetching project files:', error);
      setSelectedFiles([]); // Optionally reset to an empty array on error
    }

    // fetch contractors
    try {
      const contractorService = new ContractorService(httpClient);
      const response: Contractor[] = await contractorService.getAllContractors();
      setContractors(response);
      console.log('Contractors found:', response);
    }
    catch (error) {
      console.error('Error fetching contractors:', error);
    }
    //fetch employees
    try {
      const response: Employee[] = await projectService.getAllEmployees();
      setEmployees(response);
      console.log('Employees found:', response);
    }
    catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : [];
    console.log("New files:", newFiles);

    // Transform each File object into an EnhancedFile object
    const enhancedNewFiles: EnhancedFile[] = newFiles.map(file => ({
      file,
      fileId: 0, // Initially set to 0
      fileName: file.name,
      size: file.size, // Add size property
      type: file.type, // Add type property
      uploadDate: file.lastModified.toString(), // Convert to string
      projectId: selectedProject?.projectId || 0, // Initially set to 0
      s3Key: '', // Initially set to empty string
      presignedUrl: '' // Initially set to null or empty string
    }));

    console.log("Enhanced new files:", enhancedNewFiles);

    // Merge new files with the existing selected files
    setSelectedFiles(prevFiles => [...prevFiles, ...enhancedNewFiles]);
  };

  const handleFilePreview = (file: EnhancedFile) => {
    setPreviewFile(file);
  };

  const handleSaveProject = async () => {
    try {
      if (selectedProject) {
        // Update the project with selected contractors and site lead
        console.log('Selected contracotrs:', contractors);

        console.log('Saving project:', selectedProject);
        // Update existing project
        selectedProject.lastUpdated = new Date();
        await projectService.updateProject(selectedProject.projectId, selectedProject);
        // Update vehicles for the project
        if (projectVehicles.length > 0) {
          await vehicleService.updateVehicles(projectVehicles);
        }
        // Upload files for the project
        if (selectedFiles.length > 0) {
          const formData = new FormData();
          selectedFiles.forEach(file => {
            formData.append('files', file.file);
          });
          await projectService.UploadFiles(selectedProject.projectId, formData)
        }

        setSelectedProject(null);
        fetchProjects(selectedOrg as number);
        isModified && setIsModified(false);
      }
      // Set the Snackbar message and open it
      setSnackbarMessage('Project saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving the project:', error);
      // Optionally handle errors differently
      setSnackbarMessage('Failed to save the project.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleVehiclesChange = (vehicles: Vehicle[]) => {
    setIsModified(true);
    setProjectVehicles(vehicles);
  };

  const toggleVehicleModal = () => {
    setVehicleModalOpen(!isVehicleModalOpen);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: 'startDate' | 'endDate') => {
    const dateValue = new Date(event.target.value);
    setSelectedProject(prev => ({
      ...prev!,
      [field]: dateValue,
    }));
  };

  const handleProjectChange = (name: string, value: string) => {
    setIsModified(true);
    setSelectedProject(prev => ({
      ...prev!,
      [name]: value,
    }));
  };

  return (
    <Box sx={{ p: 3, bgcolor: 'background.default', minHeight: '100vh' }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: 'black' }}>Project Dashboard</Typography>

    <Paper elevation={3} sx={{ p: 3, mb: 4, width: { xs: '100%', sm: '60%', md: '40%', lg: '20%' } }}> {/* Smaller width, aligned to the left */}
      <FormControl fullWidth variant="outlined">
        <InputLabel id="org-select-label">Select an Organization</InputLabel>
        <Select
          labelId="org-select-label"
          id="org-select"
          value={selectedOrg || 0}
          label="Select an Organization"
          onChange={handleOrgChange}
        >
          <MenuItem value={0}><em>None</em></MenuItem>
          {organizations.map((org) => (
            <MenuItem key={org.organizationId} value={org.organizationId}>{org.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  
      {selectedOrg && (
        <Box sx={{ mb: 4, width: '100%' }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
              <CircularProgress />
            </Box>
          ) : (
            projects.length > 0 ? (
              <ProjectTable projects={projects} onEditClick={handleEditProjectClick} />
            ) : (
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>No projects found for the selected organization.</Typography>
            )
          )}
        </Box>
      )}
  
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ width: '90%' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      
      <ProjectDialog
        open={selectedProject !== null}
        onClose={() => setSelectedProject(null)}
        project={selectedProject}
        onDateChange={handleDateChange}
        onVehicleModalToggle={toggleVehicleModal}
        onFileChange={handleFileChange}
        onSave={handleSaveProject}
        files={selectedFiles}
        previewFile={previewFile}
        onPreviewClose={() => setPreviewFile(null)}
        onFilePreview={handleFilePreview}
        contractors={contractors}
        employees={employees}
        contractorService={new ContractorService(httpClient)}
        onProjectChange={handleProjectChange}
        isModified={isModified}
      />

      <VehicleComponent
        show={isVehicleModalOpen}
        onClose={toggleVehicleModal}
        onVehiclesChange={handleVehiclesChange}
        initialVehicles={selectedProject?.vehicles || []}
      />
    </Box>
  );
}

export default ProjectDashboard;
