// EquipmentSection.tsx

import React, { useCallback } from 'react';
import { Paper, Typography, Box, IconButton, TextField, Card } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import EquipmentFileDetail from '../../Models/EquipmentFileDetail';
import VehicleEquipment from '../../Models/VehicleEquipment';
import getImageSrc from '../../Utilities/GetImageSrc';

interface EquipmentSectionProps {
  vehicleEquipment: VehicleEquipment[];
  equipmentDetails: EquipmentFileDetail[];
  onEquipmentChange: (
    equipmentId: number,
    instanceIndex: number,
    field: keyof EquipmentFileDetail,
    value: any
  ) => void;
}

const EquipmentSection: React.FC<EquipmentSectionProps> = ({
  vehicleEquipment,
  equipmentDetails,
  onEquipmentChange,
}) => {
  const handleFileChange = useCallback(
    (equipmentId: number, instanceIndex: number, field: keyof EquipmentFileDetail) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
          onEquipmentChange(equipmentId, instanceIndex, field, file);
        }
      },
    [onEquipmentChange]
  );

  const getInitializedEquipmentDetails = useCallback(() => {
    return vehicleEquipment
      .map((eq) =>
        Array.from({ length: eq.quantity }, (_, index) => {
          const instanceIndex = index;
          return (
            equipmentDetails.find(
              (e) => e.equipmentId === eq.equipmentId && e.instanceIndex === instanceIndex
            ) || {
              equipmentId: eq.equipmentId,
              instanceIndex,
              equipmentImage: null,
              equipmentImagePath: null,
              serialNumberImage: null,
              serialNumberImagePath: null,
              serialNumberText: '',
              installationImage: null,
              installationImagePath: null,
              calibrationImage: null,
              calibrationImagePath: null,
            }
          );
        })
      )
      .flat();
  }, [vehicleEquipment, equipmentDetails]);

  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
      {vehicleEquipment.map((eq) =>
        Array.from({ length: eq.quantity }, (_, index) => {
          const instanceIndex = index;
          const equipment = getInitializedEquipmentDetails().find(
            (e) => e.equipmentId === eq.equipmentId && e.instanceIndex === instanceIndex
          );

          const equipmentImageSrc = getImageSrc(equipment?.equipmentImage ?? null, equipment?.equipmentImagePath ?? null);
          const serialNumberImageSrc = getImageSrc(equipment?.serialNumberImage ?? null, equipment?.serialNumberImagePath ?? null);
          const installationImageSrc = getImageSrc(equipment?.installationImage ?? null, equipment?.installationImagePath ?? null);
          const calibrationImageSrc = getImageSrc(equipment?.calibrationImage ?? null, equipment?.calibrationImagePath ?? null);

          return (
            <Card variant="outlined" sx={{ p: 2, mb: 2 }} key={`${eq.equipmentId}-${instanceIndex}`}>
              <Typography variant="h6">{`${eq.name} #${index + 1}`}</Typography>
              {/* Equipment Image */}
              <Typography variant="subtitle1">{`${eq.name} Image`}</Typography>
              <Box display="flex" alignItems="center">
                <label htmlFor={`equipment-image-${eq.equipmentId}-${instanceIndex}`}>
                  <IconButton color="primary" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  id={`equipment-image-${eq.equipmentId}-${instanceIndex}`}
                  onChange={handleFileChange(eq.equipmentId, instanceIndex, 'equipmentImage')}
                />
                {equipmentImageSrc && (
                  <img
                    src={equipmentImageSrc}
                    alt={`Equipment Image #${eq.equipmentId} - ${index + 1}`}
                    style={{ maxWidth: '100%', maxHeight: 200 }}
                  />
                )}
              </Box>

              {/* Serial Number Image */}
              <Typography variant="subtitle1">Serial Number Image</Typography>
              <Box display="flex" alignItems="center">
                <label htmlFor={`serial-number-image-${eq.equipmentId}-${instanceIndex}`}>
                  <IconButton color="primary" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  id={`serial-number-image-${eq.equipmentId}-${instanceIndex}`}
                  onChange={handleFileChange(eq.equipmentId, instanceIndex, 'serialNumberImage')}
                />
                {serialNumberImageSrc && (
                  <img
                    src={serialNumberImageSrc}
                    alt={`Serial Number Image #${eq.equipmentId} - ${index + 1}`}
                    style={{ maxWidth: '100%', maxHeight: 200 }}
                  />
                )}
              </Box>

              {/* Serial Number Text */}
              <TextField
                label="Serial Number"
                fullWidth
                value={equipment?.serialNumberText || ''}
                onChange={(e) =>
                  onEquipmentChange(eq.equipmentId, instanceIndex, 'serialNumberText', e.target.value)
                }
                margin="normal"
              />

              {/* Installation Image */}
              <Typography variant="subtitle1">Installation Image</Typography>
              <Box display="flex" alignItems="center">
                <label htmlFor={`installation-image-${eq.equipmentId}-${instanceIndex}`}>
                  <IconButton color="primary" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  id={`installation-image-${eq.equipmentId}-${instanceIndex}`}
                  onChange={handleFileChange(eq.equipmentId, instanceIndex, 'installationImage')}
                />
                {installationImageSrc && (
                  <img
                    src={installationImageSrc}
                    alt={`Installation Image #${eq.equipmentId} - ${index + 1}`}
                    style={{ maxWidth: '100%', maxHeight: 200 }}
                  />
                )}
              </Box>

              {/* Calibration Image */}
              <Typography variant="subtitle1">Calibration Image</Typography>
              <Box display="flex" alignItems="center">
                <label htmlFor={`calibration-image-${eq.equipmentId}-${instanceIndex}`}>
                  <IconButton color="primary" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  id={`calibration-image-${eq.equipmentId}-${instanceIndex}`}
                  onChange={handleFileChange(eq.equipmentId, instanceIndex, 'calibrationImage')}
                />
                {calibrationImageSrc && (
                  <img
                    src={calibrationImageSrc}
                    alt={`Calibration Image #${eq.equipmentId} - ${index + 1}`}
                    style={{ maxWidth: '100%', maxHeight: 200 }}
                  />
                )}
              </Box>
            </Card>
          );
        })
      )}
    </Paper>
  );
};

export default EquipmentSection;