const PostInstallQuestions = [
    { id: 'windowsWindshieldMirrorsCondition', question: 'Are windows, windshield, or mirrors free of cracks, or breaks?' },
    { id: 'windshieldWipersOperational', question: 'Are windshield wipers operational?' },
    { id: 'vehicleEngineOperation', question: 'Is the vehicle engine operation, without codes?' },
    { id: 'frontDoorOperational', question: 'Is the front door operational?' },
    { id: 'rearDoorOperational', question: 'Is the rear door operational?' },
    { id: 'wheelchairLiftOperational', question: 'Is the wheelchair lift operational?' },
    { id: 'radioPAOperational', question: 'Is the radio/PA operational?' },
    { id: 'interiorLightsOperational', question: 'Are the interior lights operational? (aisle lamps, door lamps)' },
    { id: 'vehiclePanelsCondition', question: 'Are the vehicle panels have all screws/rivets and are not cracked/broken?' },
    { id: 'heatACDefrostOperational', question: 'Is the heat AC, & defrost systems operational?' },
    { id: 'hornOperational', question: 'Is the horn operational?' },
    { id: 'exteriorLightsOperational', question: 'Are the exterior lights operational? (running, backup, 8-ways)' },
    { id: 'interlockOperational', question: 'Is the interlock operational?' },
    { id: 'speakersOperational', question: 'Are the speakers operational?' },
    { id: 'vehicleCleanliness', question: 'Is the vehicle clean from installation debris?' }
  ];
  
export default PostInstallQuestions;