import EnhancedFile from "../Models/EnhancedFile";
import Quote from "../Models/Quote";
import VehicleEquipment from "../Models/VehicleEquipment";

class QuoteService {
    baseUrl: string;
    httpClient: any;

    constructor(httpClient: any) {
        this.baseUrl = process.env.REACT_APP_API_URL!;
        this.httpClient = httpClient;
    }

    async CreateQuote(quote: Quote) {
        try {
            const response = await this.httpClient.post(`${this.baseUrl}/Quote/createQuote`, quote);
            return response.data;
        } catch (error) {
            console.error("Error creating quote:", error);
            throw error;
        }
    }

    async CreateProjectFromQuote(selectedQuote: Quote) {
        try {
            const response = await this.httpClient.post(`${this.baseUrl}/Quote/fromQuote`, selectedQuote);
            return response.data;
        } catch (error) {
            console.error("Error creating quote:", error);
            throw error;
        }
    }

    async UpdateQuote(quoteId: number, quote: Quote) {
        try {
            const response = await this.httpClient.put(`${this.baseUrl}/Quote/updateQuote/${quoteId}`, quote);
            return response.data;
        } catch (error) {
            console.error("Error creating quote:", error);
            throw error;
        }
    }

    async getQuotesByOrganizationId(orgId: number) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/Quote/getQuotesByOrgId/${orgId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching project with id ${orgId}:`, error);
            throw error;
        }
    }

    async getQuoteByProjectId(projectId: number) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/Quote/getquotebyprojectid/${projectId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching project with id ${projectId}:`, error);
            throw error;
        }
    }

    async UploadFiles(quoteId: number, formData: FormData) {
        try {
          const response = await this.httpClient.post(`${this.baseUrl}/Quote/upload/${quoteId}`, formData);
          return response.data;
        } catch (error) {
          console.error(`Error uploading files:`, error);
          throw error;
        }
      }

    async HandleLoadFiles(quoteId: number) {
        try {
          const response = await this.httpClient.get(`${this.baseUrl}/Quote/${quoteId}/files`);
          const transformedData = response.data.map((file: EnhancedFile) => ({
            ...file,
            // Additional transformations if needed
            // For example, converting date strings to Date objects
            uploadDate: new Date(file.uploadDate)
          }));
          console.log('Transformed files:', transformedData);
          return transformedData;
        } catch (error) {
          console.error(`Error uploading files:`, error);
          throw error;
        }
      }

}

export default QuoteService;