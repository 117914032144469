// PreCheckQuestions.tsx
import React from 'react';
import {
  Grid, Typography, RadioGroup, FormControlLabel, Radio, FormControl, Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
interface PreCheckQuestionsProps {
  questions: { id: string; question: string }[];
  answers: { [key: string]: string };
  onAnswerChange: (questionId: string, answer: string) => void;
}

const PreCheckQuestions: React.FC<PreCheckQuestionsProps> = ({ questions, answers, onAnswerChange }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
          Pre Check Questions
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" fullWidth variant="standard">
          {questions.map((question) => (
            <Grid container spacing={2} key={question.id} sx={{ mb: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>{question.question}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioGroup
                  row
                  name={question.id}
                  value={answers[question.id] || 'no'}
                  onChange={(e) => onAnswerChange(question.id, e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="na" control={<Radio />} label="N/A" />
                </RadioGroup>
              </Grid>
            </Grid>
          ))}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default PreCheckQuestions;
