import React, { useRef, useEffect, useState } from 'react';
import { Card, Typography, Box, Button } from '@mui/material';
import SignaturePad from 'react-signature-canvas';

interface SignatureSectionProps {
    type: string; // Could be 'general', 'siteLead', 'projectManager', or 'admin'
    signature: string; // URL or base64 encoded image string
    onSignatureComplete: (type: string, signature: string) => void;
    onClearSignature: (type: string) => void;
    readonly: boolean; // If true, the signature pad is disabled, and only the image is shown
}

export const SignatureSection: React.FC<SignatureSectionProps> = ({ type, signature, onSignatureComplete, onClearSignature, readonly }) => {
    const sigPadRef = useRef<SignaturePad>(null);
    const [isSigned, setIsSigned] = useState(false);

    useEffect(() => {
        setIsSigned(!!signature);
    }, [signature]);

    const handleClear = () => {
        if (sigPadRef.current && !readonly) {
            sigPadRef.current.clear();
            onClearSignature(type);
            setIsSigned(false);
        }
    };

    const handleSaveSignature = () => {
        if (sigPadRef.current && !readonly) {
            const sigImage = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
            onSignatureComplete(type, sigImage);
            setIsSigned(true);
        }
    };

    return (
        <Card variant="outlined" sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" gutterBottom>
                {`Signature`}
            </Typography>
            <Box sx={{ border: '1px solid black', padding: 2 }}>
                {readonly ? (
                    signature && <img src={signature} alt={`${type} signature`} style={{ width: '100%', height: 'auto' }} />
                ) : (
                    <Box sx={{ width: '100%', height: 200, position: 'relative' }}>
                        <SignaturePad
                            ref={sigPadRef}
                            penColor="black"
                            canvasProps={{ className: 'sigCanvas', style: { width: '100%', height: '100%' } }}
                            onEnd={handleSaveSignature}
                        />
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button onClick={handleClear} variant="outlined" color="secondary" disabled={readonly || !isSigned}>
                    Clear Signature
                </Button>
            </Box>
        </Card>
    );
};

export default SignatureSection;
