import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Alert,
  LinearProgress,
} from '@mui/material';
import axios from 'axios';
import EquipmentSection from '../ContractorInspectionComponents/EquipmentSection';
import ReviewSection from '../ContractorInspectionComponents/ReviewSection';
import { SignatureSection } from '../ContractorInspectionComponents/SignatureSection';
import VehicleFormSection from '../ContractorInspectionComponents/VehicleFormSection';
import InspectionFormObject from '../../Models/InspectionForm';
import EquipmentFileDetail from '../../Models/EquipmentFileDetail';
import Contractor from '../../Models/Contractor';
import VehicleEquipment from '../../Models/VehicleEquipment';
import Vehicle from '../../Models/Vehicle';
import WarningIcon from '@mui/icons-material/Warning';
import InstallationCheckQuestions from '../ContractorInspectionComponents/QuestionSections/InstallationCheckQuestions';
import PreCheckQuestions from '../ContractorInspectionComponents/QuestionSections/PreCheckQuestions';
import PostInstallQuestions from '../ContractorInspectionComponents/QuestionSections/PostInstallQuestions';
import preCheckQuestions from '../../Utilities/PreCheckQuestions';
import installationCheckQuestions from '../../Utilities/InstallationCheckQuestions';
import postInstallQuestions from '../../Utilities/PostInstallQuestions';
import DiscrepancySection from '../ContractorInspectionComponents/DiscrepancySection';
import ContractorService from '../../Services/ContractorService';
import EquipmentService from '../../Services/EquipmentService';
import InspectionFormsService from '../../Services/InspectionFormsService';
import { initLogGroupAndStream, logError, logInfo } from '../../Utilities/AWSConfig'; // Others may be used but disabled for now
import ValidationErrors from '../../Models/ValidationErrors';

interface InspectionFormProps {
  mode: 'submit' | 'review' | 'reviewPM' | 'adminApproval';
  open: boolean;
  onClose: () => void;
  initialVehicle: Vehicle;
  onSave: (data: InspectionFormObject, vehicle: Vehicle) => Promise<void>;
  contractorDetails: Contractor;
  onReview: (data: InspectionFormObject) => Promise<void>;
}

const InspectionForm: React.FC<InspectionFormProps> = ({
  mode,
  open,
  onClose,
  initialVehicle,
  onSave,
  contractorDetails,
  onReview,
}) => {
  const initialFormState: InspectionFormObject = {
    formId: null,
    contractorId: '',
    vehicleId: '',
    vehicleIdImage: null,
    vehicleIdImagePath: '',
    vinImage: null,
    vinImagePath: '',
    discrepancyDescription: '',
    discrepancyImages: [],
    discrepancyImagePaths: [],
    inspectionDate: new Date(),
    installationCheckAnswersSerialized: '',
    preCheckAnswersSerialized: '',
    postInstallAnswersSerialized: '',
    signature: '',
    status: '',
    statusSiteLead: '',
    siteLeadComments: '',
    siteLeadSignature: '',
    projectManagerApprovalStatus: '',
    projectManagerComments: '',
    projectManagerSignature: '',
    adminApprovalStatus: '',
    adminApprovalComments: '',
    adminSignature: '',
    equipmentDetails: [],
    vehicleIdImageBase64: '',
    vinImageBase64: '',
    discrepancyImagesBase64: [],
    assistingContractorId: 0,
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
  };

  const [formState, setFormState] = useState<InspectionFormObject>(initialFormState);
  const [isInitialized, setIsInitialized] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [assistingContractorList, setAssistingContractorList] = useState<Contractor[]>([]);
  const [vehicleEquipment, setVehicleEquipment] = useState<VehicleEquipment[]>([]);
  const [updatedVehicle, setUpdatedVehicle] = useState<Vehicle | null>(initialVehicle);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [projectManagerStatus, setProjectManagerStatus] = useState<string>('');
  const [adminStatus, setAdminStatus] = useState<string>('');

  const httpClient = useMemo(() => axios.create(), []);
  const contractorService = useMemo(() => new ContractorService(httpClient), [httpClient]);
  const equipmentService = useMemo(() => new EquipmentService(httpClient), [httpClient]);
  const inspectionFormsService = useMemo(() => new InspectionFormsService(httpClient), [httpClient]);

  const fileReaderWorker = useMemo(
    () => new Worker(new URL('../../Utilities/fileReader.worker.ts', import.meta.url)),
    []
  );

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      fileReaderWorker.postMessage(file);

      fileReaderWorker.onmessage = (e) => {
        if (e.data.error) {
          reject(e.data.error);
        } else {
          resolve(e.data);
        }
      };
    });
  };

  // Handle online/offline status
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
  
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Sync data when back online (currently not doing anything since IndexedDB is removed)
  useEffect(() => {
    const syncData = async () => {
      if (isOnline && mode === 'submit') {
        try {
          // Placeholder for future sync logic
        } catch (error) {
          setSnackbarMessage('Error syncing data.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          await logError({ message: 'Error syncing data', error });
        }
      }
    };

    syncData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  // Clean up the worker on unmount
  useEffect(() => {
    return () => {
      fileReaderWorker.terminate();
    };
  }, [fileReaderWorker]);

  // Initialize form data
  useEffect(() => {
    const initData = async () => {
      if (!initialVehicle) return;

      setFormState(initialFormState);

      try {
        const [contractorData, equipmentData, formData] = await Promise.all([
          contractorService.getAllContractors(),
          equipmentService.GetVehicleEquipmentByVehicleId(initialVehicle.vehicleId),
          inspectionFormsService.getInspectionFormByVehicleId(initialVehicle.vehicleId),
        ]);

        setAssistingContractorList(contractorData);
        setVehicleEquipment(equipmentData);

        setFormState((prevState) => ({
          ...initialFormState,
          formId: formData.formId || 0,
          contractorId: contractorDetails.contractorId.toString(),
          vehicleId: initialVehicle.vehicleId,
          assistingContractorId: formData.assistingContractorId ?? 0,
          equipmentDetails: equipmentData,
          discrepancyImagesBase64: formData.discrepancyImagesBase64 || [],
          discrepancyImagePaths: formData.discrepancyImagePaths || [],
          discrepancyDescription: formData.discrepancyDescription || '',
          inspectionDate: formData.inspectionDate ? new Date(formData.inspectionDate) : new Date(),
          signature: formData.signature || '',
          status: formData.status || '',
          statusSiteLead: formData.statusSiteLead || '',
          siteLeadComments: formData.siteLeadComments || '',
          siteLeadSignature: formData.siteLeadSignature || '',
          projectManagerApprovalStatus: formData.projectManagerApprovalStatus || '',
          projectManagerComments: formData.projectManagerComments || '',
          projectManagerSignature: formData.projectManagerSignature || '',
          adminApprovalStatus: formData.adminApprovalStatus || '',
          adminApprovalComments: formData.adminApprovalComments || '',
          adminSignature: formData.adminSignature || '',
          vehicleIdImageBase64: formData.vehicleIdImageBase64 || '',
          vinImageBase64: formData.vinImageBase64 || '',
          createdDate: formData.createdDate ? new Date(formData.createdDate) : new Date(),
          lastUpdatedDate: formData.lastUpdatedDate ? new Date(formData.lastUpdatedDate) : new Date(),
          installationCheckAnswersSerialized: formData.installationCheckAnswersSerialized || '',
          preCheckAnswersSerialized: formData.preCheckAnswersSerialized || '',
          postInstallAnswersSerialized: formData.postInstallAnswersSerialized || '',
          ...formData,
        }));

        setUpdatedVehicle(initialVehicle);
        // Set project manager and admin statuses based on form data, Can't remove these to refresh in dashboard
        setProjectManagerStatus(
          formData.projectManagerApprovalStatus === 'Project Manager Approved'
            ? 'Approved'
            : formData.projectManagerApprovalStatus === 'Project Manager Declined'
              ? 'Declined'
              : ''
        );

        setAdminStatus(
          formData.adminApprovalStatus === 'Admin Approved'
            ? 'Approved'
            : formData.adminApprovalStatus === 'Admin Declined'
              ? 'Declined'
              : ''
        );
        setIsInitialized(true);
      
      } catch (error) {
        await logError({ message: 'Initialization error', error });
      }
    };

    if (initialVehicle) {
      initData();
    }
  }, [initialVehicle, contractorService, equipmentService, inspectionFormsService, contractorDetails.contractorId]);

  // Removed the problematic useEffect that was causing infinite re-renders
  // useEffect(() => {
  //   if (isInitialized) {
  //     setTimeout(() => {
  //       setIsInitialized(true);
  //     }, 100); // Short delay to stabilize rendering
  //   }
  // }, [isInitialized]);

  const calculateCompletion = (): number => {
    // Initial required fields
    let totalRequiredFields = 6; // Vehicle fields and signature
    let completedFields = 0;

    // Vehicle fields
    if (updatedVehicle?.make && updatedVehicle.make.trim() !== '') completedFields++;
    if (updatedVehicle?.model && updatedVehicle.model.trim() !== '') completedFields++;
    if (
      updatedVehicle?.year &&
      !isNaN(Number(updatedVehicle.year)) &&
      Number(updatedVehicle.year) >= 1900 &&
      Number(updatedVehicle.year) <= new Date().getFullYear()
    ) {
      completedFields++;
    }
    if (updatedVehicle?.vehicleNumber && updatedVehicle.vehicleNumber.trim() !== '') completedFields++;
    if (updatedVehicle?.vin && updatedVehicle.vin.trim() !== '') completedFields++; // VIN included
    if (formState.signature && formState.signature.trim() !== '') completedFields++;

    // Equipment fields
    const requiredEquipmentFieldsPerItem = 3; // Adjust as necessary
    const equipmentItems = formState.equipmentDetails || [];
    const totalEquipmentFields = equipmentItems.length * requiredEquipmentFieldsPerItem;

    // Update total required fields
    totalRequiredFields += totalEquipmentFields;

    // Calculate completed equipment fields
    equipmentItems.forEach((item) => {
      if (item.equipmentImagePath) completedFields++;
      if (item.serialNumberText && item.serialNumberText.trim() !== '') {
        completedFields++;
      } else if (item.serialNumberImagePath) {
        completedFields++;
      }
      if (item.installationImagePath) completedFields++;
      // Add other required equipment fields as needed
    });

    // Calculate completion percentage
    const completionPercentage = (completedFields / totalRequiredFields) * 100;

    return completionPercentage;
  };

  const retryWithBackoff = async (fn: () => Promise<any>, retries = 3, delay = 1000): Promise<any> => {
    try {
      return await fn();
    } catch (error) {
      if (retries === 0) throw error;
      await new Promise((resolve) => setTimeout(resolve, delay));
      return retryWithBackoff(fn, retries - 1, delay * 2);
    }
  };

  const handleEquipmentChange = async (
    equipmentId: number,
    instanceIndex: number,
    field: keyof EquipmentFileDetail,
    value: any
  ): Promise<void> => {
    if (field === 'serialNumberText') {
      // Handle text input changes immediately
      setFormState((prevFormState) => {
        const equipmentDetails = [...prevFormState.equipmentDetails];
        const detailIndex = equipmentDetails.findIndex(
          (detail) => detail.equipmentId === equipmentId && detail.instanceIndex === instanceIndex
        );

        if (detailIndex !== -1) {
          // Update existing detail
          equipmentDetails[detailIndex] = {
            ...equipmentDetails[detailIndex],
            [field]: value,
          };
        } else {
          // Add new detail
          const newDetail: EquipmentFileDetail = {
            equipmentId,
            instanceIndex,
            equipmentImage: null,
            equipmentImageBase64: '',
            equipmentImagePath: '',
            serialNumberImage: null,
            serialNumberImageBase64: '',
            serialNumberImagePath: '',
            serialNumberText: value, // Set the serialNumberText value
            installationImage: null,
            installationImageBase64: '',
            installationImagePath: '',
            calibrationImage: null,
            calibrationImageBase64: '',
            calibrationImagePath: '',
          };
          equipmentDetails.push(newDetail);
        }

        return {
          ...prevFormState,
          equipmentDetails,
        };
      });

      // No IndexedDB saving
    } else if (
      field === 'equipmentImage' ||
      field === 'serialNumberImage' ||
      field === 'installationImage' ||
      field === 'calibrationImage'
    ) {
      setIsUploading(true); // Start the spinner
      try {
        // Handle file upload asynchronously
        const file = value as File;
        const tempUrl = URL.createObjectURL(file);

        // Store the File object and temp URL in the formState
        setFormState((prevFormState) => {
          const equipmentDetails = [...prevFormState.equipmentDetails];
          const detailIndex = equipmentDetails.findIndex(
            (detail) => detail.equipmentId === equipmentId && detail.instanceIndex === instanceIndex
          );

          if (detailIndex !== -1) {
            // Update existing detail
            equipmentDetails[detailIndex] = {
              ...equipmentDetails[detailIndex],
              [field]: file, // Store the File object
              [`${field}Path`]: tempUrl, // Store the temp URL
            };
          } else {
            // Add new detail
            const newDetail: EquipmentFileDetail = {
              equipmentId,
              instanceIndex,
              equipmentImage: null,
              equipmentImageBase64: '',
              equipmentImagePath: '',
              serialNumberImage: null,
              serialNumberImageBase64: '',
              serialNumberImagePath: '',
              serialNumberText: '',
              installationImage: null,
              installationImageBase64: '',
              installationImagePath: '',
              calibrationImage: null,
              calibrationImageBase64: '',
              calibrationImagePath: '',
              [field]: file, // Store the File object
              [`${field}Path`]: tempUrl, // Store the temp URL
            };
            equipmentDetails.push(newDetail);
          }

          return {
            ...prevFormState,
            equipmentDetails,
          };
        });

        await retryWithBackoff(async () => {
          // Upload the file
          const fileBase64 = await readFileAsBase64(file);
          const { url: uploadedPath, formId: returnedFormId } = await inspectionFormsService.uploadEquipmentFile(
            fileBase64,
            field,
            formState.formId!,
            equipmentId,
            instanceIndex,
            formState.contractorId,
            formState.vehicleId
          );

          // Optionally update the equipmentImagePath with the uploaded path
          setFormState((prevFormState) => {
            const equipmentDetails = [...prevFormState.equipmentDetails];
            const detailIndex = equipmentDetails.findIndex(
              (detail) => detail.equipmentId === equipmentId && detail.instanceIndex === instanceIndex
            );

            if (detailIndex !== -1) {
              equipmentDetails[detailIndex] = {
                ...equipmentDetails[detailIndex],
                [`${field}Base64`]: fileBase64,
                [`${field}Path`]: uploadedPath || equipmentDetails[detailIndex][`${field}Path`],
              };
            }

            return {
              ...prevFormState,
              formId: returnedFormId || prevFormState.formId,
              equipmentDetails,
            };
          });

          setIsUploading(false);
        });
      } catch (error) {
        setUploadError(`Failed to upload ${field} after multiple attempts.`);
        console.error(`Error in handleEquipmentChange: ${(error as Error).message}`);
      } finally {
        setIsUploading(false); // Ensure spinner is stopped
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const validateField = (field: string, value: any): string => {
    let error = '';

    switch (field) {
      case 'make':
        if (!value || value.trim() === '') {
          error = 'Make is required.';
        }
        break;
      case 'model':
        if (!value || value.trim() === '') {
          error = 'Model is required.';
        }
        break;
      case 'year':
        if (
          !value ||
          isNaN(Number(value)) ||
          Number(value) < 1900 ||
          Number(value) > new Date().getFullYear()
        ) {
          error = 'Enter a valid year.';
        }
        break;
      case 'vehicleNumber':
        if (!value || value.trim() === '') {
          error = 'Vehicle Number is required.';
        }
        break;
      case 'vin':
        if (!value || value.trim() === '') {
          error = 'VIN is required.';
        }
        break;
      // Add more cases for other fields as needed
      default:
        break;
    }

    return error;
  };

  const validateForm = (): boolean => {


    let valid = true;
    const newErrors: ValidationErrors = {};
    // Validate vehicle fields
    if (!updatedVehicle?.make || updatedVehicle?.make.trim() === '') {
      newErrors.make = 'Make is required.';
      valid = false;
    }
    if (!updatedVehicle?.model || updatedVehicle?.model.trim() === '') {
      newErrors.model = 'Model is required.';
      valid = false;
    }
    if (
      !updatedVehicle?.year ||
      isNaN(Number(updatedVehicle?.year)) ||
      Number(updatedVehicle?.year) < 1900 ||
      Number(updatedVehicle?.year) > new Date().getFullYear()
    ) {
      newErrors.year = 'Enter a valid year.';
      valid = false;
    }
    if (!updatedVehicle?.vehicleNumber || updatedVehicle?.vehicleNumber?.trim() === '') {
      newErrors.vehicleNumber = 'Vehicle Number is required.';
      valid = false;
    }

    // Update the errors state
    setErrors(newErrors);

    return valid;
  };

  const validateReviewSignature = (): boolean => {

    if (mode === 'review' && (!formState.siteLeadSignature || !formState.statusSiteLead)) {
      return false;
    }
    if (mode === 'reviewPM' && (!formState.projectManagerSignature || !formState.projectManagerApprovalStatus)) {
      return false;
    }
    if (mode === 'adminApproval' && (!formState.adminSignature || !formState.adminApprovalStatus)) {
      return false;
    }
    return true;
  };
  

  const handleSubmit = async () => {
    if (!validateForm()) {
      setSnackbarMessage('Please fix the errors in the form before submitting.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
  
    try {
      const formToSave = { ...formState, vehicleId: formState.vehicleId };

      if (isOnline) {
        await onSave(formToSave, updatedVehicle as Vehicle);
        setFormState(initialFormState);
        onClose();
      } else {
        // Since IndexedDB is removed, handle offline saving differently if needed
        setSnackbarMessage('You are offline. Data cannot be saved.');
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error saving form');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error saving form', error });
    }
  };

  const handleReviewSubmit = async () => {
    if (!validateReviewSignature()) {
      return;
    }
    try {
      await onReview(formState);
      onClose();
    } catch (error) {
      setSnackbarMessage('Error submitting review');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error submitting review', error });
    }
  };

  const handleVehicleChange = useCallback((field: keyof Vehicle, value: any) => {
    setUpdatedVehicle((prevVehicle) => ({
      ...prevVehicle!,
      [field]: value,
    }) as Vehicle);

    // Validate the field
    const error = validateField(field, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  }, []);

  const handleStatusChange = (reviewType: string, status: string) => {
    setFormState((prevState) => {
      let newState;
      if (reviewType === 'Site Lead') {
        newState = { ...prevState, statusSiteLead: status };
      } else if (reviewType === 'Project Manager') {
        newState = {
          ...prevState,
          projectManagerApprovalStatus:
            status === 'Approved' ? 'Project Manager Approved' : 'Project Manager Declined',
        };
      } else if (reviewType === 'Admin') {
        newState = { ...prevState, adminApprovalStatus: status === 'Approved' ? 'Admin Approved' : 'Admin Declined' };
      } else {
        newState = prevState;
      }
      return newState;
    });
  };

  const handleCommentChange = (reviewType: string, comments: string) => {
    setFormState((prevState) => {
      switch (reviewType) {
        case 'Site Lead':
          return { ...prevState, siteLeadComments: comments };
        case 'Project Manager':
          return { ...prevState, projectManagerComments: comments };
        case 'Admin':
          return { ...prevState, adminApprovalComments: comments };
        default:
          return prevState;
      }
    });
  };


  const handleSignatureComplete = (type: string, signature: string) => {
    setFormState((prevState) => {
      switch (type) {
        case 'general':
          return { ...prevState, signature };
        case 'Site Lead':
          return { ...prevState, siteLeadSignature: signature };
        case 'Project Manager':
          return { ...prevState, projectManagerSignature: signature };
        case 'Admin':
          return { ...prevState, adminSignature: signature };
        default:
          return prevState;
      }
    });
  };
  

  const handleClearSignature = (type: string) => {
    setFormState((prevState) => {
      switch (type) {
        case 'general':
          return { ...prevState, signature: '' };
        case 'Site Lead':
          return { ...prevState, siteLeadSignature: '' };
        case 'Project Manager':
          return { ...prevState, projectManagerSignature: '' };
        case 'Admin':
          return { ...prevState, adminSignature: '' };
        default:
          return prevState;
      }
    });
  };

  const renderReviewComments = () => {
    const comments = [];

    if (mode !== 'review' && mode !== 'reviewPM' && mode !== 'adminApproval' && formState.siteLeadComments) {
      comments.push({ type: 'Site Lead', text: formState.siteLeadComments });
    }

    if (mode === 'review' && formState.projectManagerComments) {
      comments.push({ type: 'Project Manager', text: formState.projectManagerComments });
    }

    if (mode === 'reviewPM' && formState.adminApprovalComments) {
      comments.push({ type: 'Admin', text: formState.adminApprovalComments });
    }

    if (comments.length === 0) return null;

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Card variant="outlined" sx={{ borderColor: 'red', backgroundColor: '#fff5f5', borderRadius: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'red', mb: 2 }}>
              <WarningIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Attention Needed
              </Typography>
            </Box>
            {comments.map((comment, index) => (
              <Box key={index} sx={{ mb: 2, pl: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'red', mb: 1 }}>
                  {comment.type} Comments:
                </Typography>
                <Typography variant="body2" sx={{ color: 'black', pl: 2 }}>
                  {comment.text}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const handlePreCheckChange = (questionId: string, answer: string) => {
    setFormState((prev) => ({
      ...prev,
      preCheckAnswersSerialized: JSON.stringify({
        ...JSON.parse(prev.preCheckAnswersSerialized || '{}'),
        [questionId]: answer,
      }),
    }));
  };

  const handleInstallationCheckChange = (questionId: string, answer: string) => {
    setFormState((prev) => ({
      ...prev,
      installationCheckAnswersSerialized: JSON.stringify({
        ...JSON.parse(prev.installationCheckAnswersSerialized || '{}'),
        [questionId]: answer,
      }),
    }));
  };

  const handlePostInstallChange = (questionId: string, answer: string) => {
    setFormState((prev) => ({
      ...prev,
      postInstallAnswersSerialized: JSON.stringify({
        ...JSON.parse(prev.postInstallAnswersSerialized || '{}'),
        [questionId]: answer,
      }),
    }));
  };

  const handleDiscrepancyDescriptionChange = (description: string) => {
    setFormState((prevState) => ({
      ...prevState,
      discrepancyDescription: description,
    }));
  };

  const handleDiscrepancyImageAdd = async (formData: FormData): Promise<string[]> => {
    setIsUploading(true);

    const files: File[] = [];
    formData.forEach((value) => {
      if (value instanceof File) {
        files.push(value);
      }
    });

    try {
      const base64Files = await Promise.all(files.map((file) => readFileAsBase64(file)));

      const formIdToUse = formState.formId || 0;

      const response = await inspectionFormsService.uploadDiscrepancyFiles(
        base64Files,
        'DiscrepancyImage',
        formIdToUse,
        formState.contractorId,
        formState.vehicleId
      );

      const { formId: returnedFormId, urls: uploadedPaths } = response || {};

      if (!uploadedPaths || !uploadedPaths.length) {
        throw new Error('No paths returned from upload');
      }

      setFormState((prevState) => {
        const newFormId = returnedFormId || prevState.formId;

        return {
          ...prevState,
          formId: newFormId,
          discrepancyImagesBase64: [...prevState.discrepancyImagesBase64, ...base64Files],
          discrepancyImagePaths: [...prevState.discrepancyImagePaths, ...uploadedPaths.filter(Boolean)],
        };
      });

      setIsUploading(false);
      return uploadedPaths;
    } catch (error) {
      setIsUploading(false);
      setSnackbarMessage('Error uploading discrepancy image');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error uploading discrepancy image', error });
      return [];
    }
  };

  const handleDiscrepancyImageRemove = (index: number) => {
    setFormState((prevState) => {
      const updatedImages = [...prevState.discrepancyImages];
      updatedImages.splice(index, 1);
      const updatedImagesBase64 = [...prevState.discrepancyImagesBase64];
      updatedImagesBase64.splice(index, 1);
      const updatedPaths = [...prevState.discrepancyImagePaths];
      updatedPaths.splice(index, 1);
      return {
        ...prevState,
        discrepancyImages: updatedImages,
        discrepancyImagesBase64: updatedImagesBase64,
        discrepancyImagePaths: updatedPaths,
      };
    });
  };

  const handlePathImageRemove = (index: number) => {
    setFormState((prevState) => {
      const updatedPaths = [...prevState.discrepancyImagePaths];
      updatedPaths.splice(index, 1);
      return {
        ...prevState,
        discrepancyImagePaths: updatedPaths,
      };
    });
  };

  function handleFieldChange(field: keyof InspectionFormObject, value: any): void {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Validate the field
    const error = validateField(field, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Vehicle Inspection Form</DialogTitle>
      <DialogContent sx={{ position: 'relative', paddingTop: '80px' }}>
        {/* Offline Alert */}
        {!isOnline && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            You are currently offline. Changes will be saved locally and synced when you're back online.
          </Alert>
        )}
        {/* Progress Bar Container */}
        <Box
          sx={{
            position: 'sticky', // Use 'sticky' positioning
            top: 0,
            backgroundColor: 'background.paper',
            zIndex: 1000,
            padding: '8px 24px',
            boxShadow: (theme) => theme.shadows[4],
          }}
        >
          <LinearProgress variant="determinate" value={calculateCompletion()} />
          <Typography variant="body2" color="textSecondary" align="right">
            {`${Math.round(calculateCompletion())}% Completed`}
          </Typography>
        </Box>
        {isUploading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
              <Typography variant="h6" component="div" color="white" sx={{ marginTop: 2 }}>
                Uploading...
              </Typography>
            </Box>
          </Box>
        )}
        {renderReviewComments()}
        <VehicleFormSection
          id="vehicle-section"
          formState={formState}
          onFieldChange={handleFieldChange}
          vehicle={updatedVehicle as Vehicle}
          onVehicleChange={handleVehicleChange}
          contractors={assistingContractorList}
          errors={errors} // Pass the errors prop
        />
        <PreCheckQuestions
          questions={preCheckQuestions}
          answers={JSON.parse(formState.preCheckAnswersSerialized || '{}')}
          onAnswerChange={handlePreCheckChange}
        />
        <Box sx={{ mb: 2 }}>
          <DiscrepancySection
            discrepancyDescription={formState.discrepancyDescription}
            discrepancyImagePaths={formState.discrepancyImagePaths}
            onDescriptionChange={handleDiscrepancyDescriptionChange}
            onImageAdd={handleDiscrepancyImageAdd}
            onPathImageRemove={handlePathImageRemove}
          />
        </Box>
        <EquipmentSection
          vehicleEquipment={vehicleEquipment}
          equipmentDetails={formState.equipmentDetails}
          onEquipmentChange={handleEquipmentChange}
        />
        <InstallationCheckQuestions
          questions={installationCheckQuestions}
          answers={JSON.parse(formState.installationCheckAnswersSerialized || '{}')}
          onAnswerChange={handleInstallationCheckChange}
        />
        <PostInstallQuestions
          questions={postInstallQuestions}
          answers={JSON.parse(formState.postInstallAnswersSerialized || '{}')}
          onAnswerChange={handlePostInstallChange}
        />
        <Box sx={{ mt: 4, mb: 2 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body1" sx={{ mb: 2 }}>
                By signing below, I, <strong>{contractorDetails.firstName || ''} {contractorDetails.lastName || ''}</strong>, certify that I have thoroughly tested the system and determined that it meets all specified requirements and functions correctly.
              </Typography>

              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                {formState.inspectionDate ?
                  `Inspection Date: ${new Date(formState.inspectionDate).toLocaleDateString()}` :
                  'Not yet signed'}
              </Typography>

              <SignatureSection
                type="general"
                signature={formState.signature ?? ''}
                onSignatureComplete={handleSignatureComplete}
                onClearSignature={handleClearSignature}
                readonly={mode === 'review' || mode === 'adminApproval' || mode === 'reviewPM'}
              />
            </CardContent>
          </Card>
        </Box>
        {(mode === 'review' || mode === 'reviewPM' || mode === 'adminApproval') && (
          <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Site Lead Review:`}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <SignatureSection
                  type="Site Lead"
                  signature={formState.siteLeadSignature}
                  onSignatureComplete={handleSignatureComplete}
                  onClearSignature={handleClearSignature}
                  readonly={mode !== 'review'}
                />
              </Box>
              <Box>
                <ReviewSection
                  reviewStatus={formState.statusSiteLead}
                  comments={formState.siteLeadComments}
                  reviewType="Site Lead"
                  onChangeStatus={handleStatusChange}
                  onChangeComments={handleCommentChange}
                />
              </Box>
            </CardContent>
          </Card>
        )}
        {(mode === 'reviewPM' || mode === 'adminApproval') && (
          <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Project Manager Review:`}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <SignatureSection
                  type="Project Manager"
                  signature={formState.projectManagerSignature}
                  onSignatureComplete={handleSignatureComplete}
                  onClearSignature={handleClearSignature}
                  readonly={mode !== 'reviewPM'}
                />
              </Box>
              <Box>
                <ReviewSection
                  reviewStatus={
                    formState.projectManagerApprovalStatus === 'Project Manager Approved'
                      ? 'Approved'
                      : formState.projectManagerApprovalStatus === 'Project Manager Declined'
                      ? 'Declined'
                      : ''
                  }
                  comments={formState.projectManagerComments}
                  reviewType="Project Manager"
                  onChangeStatus={handleStatusChange}
                  onChangeComments={handleCommentChange}
                />
              </Box>
            </CardContent>
          </Card>
        )}
        {mode === 'adminApproval' && (
          <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Admin Review:`}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <SignatureSection
                  type="Admin"
                  signature={formState.adminSignature}
                  onSignatureComplete={handleSignatureComplete}
                  onClearSignature={handleClearSignature}
                  readonly={mode !== 'adminApproval'}
                />
              </Box>
              <Box>
                <ReviewSection
                  reviewStatus={
                    formState.adminApprovalStatus === 'Admin Approved'
                      ? 'Approved'
                      : formState.adminApprovalStatus === 'Admin Declined'
                      ? 'Declined'
                      : ''
                  }
                  comments={formState.adminApprovalComments}
                  reviewType="Admin"
                  onChangeStatus={handleStatusChange}
                  onChangeComments={handleCommentChange}
                />
              </Box>
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        {mode !== 'reviewPM' && mode !== 'adminApproval' && mode !== 'review' && (
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Save
          </Button>
        )}
        {mode === 'review' && (
          <Button variant="contained" onClick={handleReviewSubmit} color="primary" disabled={!validateReviewSignature()}>
            Submit SL Review
          </Button>
        )}
        {mode === 'reviewPM' && (
          <Button variant="contained" onClick={handleReviewSubmit} color="primary" disabled={!validateReviewSignature()}>
            Submit PM Review
          </Button>
        )}
        {mode === 'adminApproval' && (
          <Button variant="contained" onClick={handleReviewSubmit} color="primary" disabled={!validateReviewSignature()}>
            Submit Admin Review
          </Button>
        )}
        <Button variant="contained" onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default InspectionForm;
