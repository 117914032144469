export const legalTerms = `1. Services:
   Independent Contractor shall be providing the installation of 12-24 volt products into fleet and/or consumer vehicles. 
   Independent Contractor will work as a team with other installers or alone depending upon the specific needs of the customer 
   and conditions of the contract. Independent Contractor shall follow and abide by all instructions and methods established by 
   the Company for the performance of Services, including among other things the performance of testing, programming and 
   configuration procedures, the completion of inventory reports and installation checklists. Independent Contractor shall report 
   directly to, and take instructions from the Project Manager, Field Manager or General Contractor. Installations MUST be performed 
   EXACTLY as described and outlined in the Installation manual and per pre-deployment Training.

   Tools: All Independent Contractors are required to possess the proper tools necessary to complete assignments outlined in Exhibit A. 
   Please review Section 3, Tools of the Installation Technician Guidelines. Required---Safety Glasses and Safety Footwear

   **IMS is not responsible for contractor’s compliance with any local, state, or federal law or order with regard to any infectious disease 
   shutdowns or protective measures. Contractor agrees it is solely responsible for determining whether any work performed pursuant to 
   this work order complies with said laws and is performed in a way that is required by OSHA regulations and other state and federal 
   guidelines regarding PPE or social distancing.**

   SERVICE FEES: Independent Contractor shall be paid for installation services rendered as outlined below: All weekly, daily and per 
   unit rates include wage. All lodging, meals, fuel, tolls, phone, Internet, and any other expenses incurred by Independent Contractor 
   are the sole responsibility of Independent Contractor. Independent Contractor hereby acknowledges that the potential exists whereby 
   vehicles scheduled for installation are not made available due to extenuating fleet customer delays, weather, or for any other reason. 
   Independent Contractor acknowledges this risk and, therefore, has the responsibility to ensure that any missed vehicle installation(s), 
   whether or not the fault is of the Independent Contractor, be rescheduled and completed as approved by the fleet customer, unless 
   otherwise directed by the IMS Project Manager. Invoice payment runs Monday through Sunday; Contractor's invoice must be submitted 
   to the office no later than Monday at 8 A.M. CST. Contractors invoice will be paid via ACH deposit on the following Friday. Failure to 
   comply with the instruction given regarding invoicing will result in delay of payment. See Section 3b of the Agreement.


2. Damage and Faulty Installation Service
In addition to the terms outlined in the Installation Service Agreement, Independent Contractor will be responsible for
damages caused by Independent Contractor. In the event damages to vehicles or fleet customer property are reported to
IMS, IMS may, at its sole discretion, charge back the cost of any such damages to any subsequent invoices submitted by
Independent Contractor to IMS. In addition, in the event any particular vehicle installation service provided by
Independent Contractor is proven to be faulty as determined by IMS in its sole discretion, IMS may, at its sole discretion,
chargeback all or a fraction of any such previously paid installation service to any subsequent invoice submitted by
Independent Contractor to IMS.

3. Statement of Work and Reporting Process
Independent Contractor will be required to complete documentation for all work performed. Documentation includes
but not limited to Google Sheets (serial numbers and vehicle information) and Photos. Independent Contractor will also
be required to report daily completion results of work performed that day. Failure to comply will result in a delay in
processing invoices, thus resulting in a delay in payment, not limited to back-charging any cost incurred with late
processing.
***Any and all changes in scope of work MUST be relayed to the Project Manager IMMEDIATELY.***

4. Service Week
Start time is to be determined by the fleet customer and General Contractor. Hours worked is dependent upon the fleet
customer’s needs and the specific contract. NO WORK—NO PAY. Schedule will be developed with the interest of working
a six-day (6) workweek on this project. IMS has no control over customer scheduling.

5. Inventory Reporting
Independent Contractor will be required to submit a weekly inventory count report of parts/supplies used for that
week's work (Monday through Sunday). Independent Contractor is responsible for all issued “spare units” and will
follow the proper RMA process. IMS reserves the right to withhold payment if the inventory count report is not
received by IMS weekly.

6. Installation Warranty
Independent Contractor warrants that all Installation Services provided for IMS will be free from defects and that all
vehicle installation services provided to IMS follow generally accepted installation standards inthe United States for a
period of one (1) year from the date of installation. In the event of improper installation, at the sole discretion of the
Company, IMS can charge cost for such remedies for defective installations to the incentive funds payable to the
Independent Contractor or any subsequent invoices submitted by Independent Contractor to IMS.`;