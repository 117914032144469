import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, Typography, Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import signInBackground from '../Images/taskForgeConcept.webp'

const SignIn = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    if (isAuthenticated && user) {
      const namespace = `${baseUrl}/roles`; // Using the correct namespace
      const userRoles = user ? user[namespace] || [] : [];
      console.log("User Roles: ", userRoles); // More detailed debugging
      if (userRoles.includes('Admin')) {
        navigate('/QuoteDashboard');
      } else if (userRoles.includes('contractor')) {
        navigate('/contractor');
      }
      // Add more role checks as needed.
      // Redirect to a general dashboard if the user's role is not explicitly handled.
      else {
        navigate('/profile');
      }
    }
  }, [isAuthenticated, user, navigate]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${signInBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Paper elevation={3} sx={{ padding: '2rem', borderRadius: '12px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          Welcome, Sign In For Your Dashboard
        </Typography>
        <Grid container justifyContent="center">
          <Button variant="contained" color="primary" size="large" onClick={() => loginWithRedirect()} sx={{ textTransform: 'none' }}>
            Sign In
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SignIn;

