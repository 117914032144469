const InstallationCheckQuestions = [
    { id: 'camerasMountedSolid', question: 'Cameras are mounted solid' },
    { id: 'cameraSetScrewsTightened', question: 'Camera set screws are tightened' },
    { id: 'exteriorCamerasSealed', question: 'Exterior cameras sealed properly' },
    { id: 'cameraCoversClean', question: 'Camera covers are clean' },
    { id: 'antennaMountedSecurely', question: 'Antenna mounted securely' },
    { id: 'correctConfigurationLoaded', question: 'Correct configuration loaded' },
    { id: 'dateTimeSet', question: 'Date and time set properly' },
    { id: 'allCamerasFunctioning', question: 'All cameras are functioning' },
    { id: 'camerasAimedCorrectly', question: 'Cameras are aimed correctly' },
    { id: 'holesHaveGrommets', question: 'All holes have grommets/loom' },
    { id: 'holesPlugged', question: 'Holes from removed items plugged' },
    { id: 'noUnauthorizedHoles', question: 'No unauthorized holes drilled' },
    { id: 'installationNotes', question: 'Installation Notes' }
];

export default InstallationCheckQuestions;